<mat-toolbar style="background: #00669A;" >
  <div  type="button"  style="color: #00669A; background: rgb(255, 255, 255); border-radius: 50mm; width: 3%;"  [routerLink]="['/']">
    <span style="margin-left: 20%; margin-top: 20%;" routerLinkActive="router-link-active"  class="material-icons">
      keyboard_backspace
      </span> 
  </div>
  <div type="button" [routerLink]="['/medical-dashboard']" style="margin-left: 1%; color: rgb(255, 255, 255);">
    Regresar 
  </div>

</mat-toolbar>
<!-- <div *ngIf="barChartData[0].data[0]!=0"  style="width: 100%; ">
    <canvas baseChart
       [datasets]="barChartData"
       [labels]="barChartLabels"
       [options]="barChartOptions"
       [legend]="barChartLegend"
       [chartType]="barChartType">
    </canvas>
</div> -->
<!-- <div style="display: block;">
  <canvas baseChart 
    [data]="pieChartData" 
    [labels]="pieChartLabels" 
    [chartType]="pieChartType"
    [options]="pieChartOptions"
    [plugins]="pieChartPlugins"
    [legend]="pieChartLegend">
  </canvas>
</div> -->
<google-chart #chart
   [title]="title"
   [type]="type"
   [data]="data"
   [columnNames]="columnNames"
   [options]="options"
   [width]="width"
   [height]="height">
</google-chart>

<div >
    <label for="example-datetime-local-input" class="col-4 col-form-label">Filtrar pacientes por </label>
    <div class="col-8">
      <div class="form__group field" style="background: rgb(201, 227, 235); padding: 2%; border-radius: 2mm;">
        <mat-select [(ngModel)]="selected">
          <mat-option *ngFor="let item of clinicsData.clinics" [value]="item.code" (click)="consultPatient(item.code)">
            {{item.name}}
          </mat-option>
          <mat-option  value="TODOS"(click)="consultPatient('todos')">
            TODOS
          </mat-option>
        </mat-select>
      </div>
    </div>
</div>

<mat-tab-group *ngIf="dataFilter!=null">
    <mat-tab label="Lista de pacientes">
      <div class="table-responsive">
        <table class="table">
          <thead class="" style="background: #00669A;">
            <tr>
              <th style="color: #ffffff;">Número de paciente</th>
              <th style="color: #ffffff;">Número de historia clínica</th>
              <th style="color: #ffffff;">Correo</th>
              <th style="color: #ffffff;">Establecimiento</th>
             
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let userData of dataFilter">
               <td>{{userData.usr_id}}</td>                
              <td>{{userData.doc_id}}</td>
              <td>{{userData.email}}</td> 
              <td>{{userData.name_stablishment}}</td>           
      
            </tr>
          </tbody>
        </table>
      </div>    
    </mat-tab>

  </mat-tab-group>