import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css']
})
export class RadioComponent implements OnInit {
  public index: string;
  @Input() public dtaQuestions: any;
  @Input() public sI: any;
  @Input() public pI: any;
  @Input() public iI: any;
  @Output() AddRmvAnswered = new EventEmitter();

  @HostListener('click', ['$event'])
  onClick(event: any): void {
    this.addRmvAnswered(event.target.name);
  }

  constructor() { }

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    const attributes = this.dtaQuestions.attributes;
    const required = attributes.find(element => element === 'required');

    this.index =  `${this.sI}.${this.pI}.${this.iI}`;

    if (required) {
      const question_label = document.getElementById(`lbl-${this.dtaQuestions.name}`);
      question_label.setAttribute("required", "true");
      question_label.setAttribute("answered", "false");
    }

    if (this.dtaQuestions.answers !== undefined) {
      const lengthQuestions = this.dtaQuestions.answers.length;

      for (let i = 0; i < lengthQuestions; i++) {
        const answer = this.dtaQuestions.answers[i];
        let element: any = document.getElementById(`${this.dtaQuestions.name}-${answer}`);
        element.checked = true;
      }

    }
  }

  addRmvAnswered(lblName: string): void {
    this.AddRmvAnswered.emit({
      question: lblName,
      answered: true
    });
  }

}
