<p class="titel_form">Configuraciones de formularios</p>

<a class="btn btn-info" role="button" [routerLink]="['/create-setting-form']" >Crear</a>

<div class="table-responsive">

  <table class="table">
    <thead class="" style="background: #7386D5;">
      <tr>
        <th>Id Config.</th>
        <th>Nombre</th>
        <th>Descripción</th>
        <th>Estructura</th>
        <th>Id Form.</th>
        <th>Creación</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let form of setFormData">
        <th>{{form.cfc_id}}</th>
        <td>{{form.name}}</td>
        <td>{{form.structure}}</td>
        <td>{{form.structure}}</td>
        <td>{{form.form_id}}</td>
        <td>{{form.created_on}}</td>
        <td><a class="btn btn-info" role="button" [routerLink]="['/edit-setting-form', form.form_id]" >Editar</a></td>
      </tr>
    </tbody>
  </table>

</div>
