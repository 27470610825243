
<!-- The Modal -->
<div id="modalFarewell" #modalPrvPlc class="modalFarewell">

  <!-- Modal content -->
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title modal_title" id="mdl-prvplc">E-ProcessMed</h3>
      <button type="button" class="close" aria-label="Close" (click)="closeModalFrw()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <form >
        <div class="container" style="text-align: justify;">
          <p>Hemos recibido satisfactoriamente su formulario y le agradecemos la información proporcionada. Por parte de E-processmed enviaremos una valoración de su información tan pronto estén los resultados.</p>
          <p>Saludos.</p>
        </div>
      </form>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-info btn-sml" id="btn_accept" (click)="closeModalFrw()" >Cerrar</button>
    </div>

  </div>

</div>

