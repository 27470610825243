import { Component, OnInit, Output, EventEmitter, Input, HostListener  } from '@angular/core';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-modal-farewell',
  templateUrl: './modal-farewell.component.html',
  styleUrls: ['./modal-farewell.component.css']
})
export class ModalFarewellComponent implements OnInit {

  public chckAcceptPlc: boolean;
  public modalPrvPlc: any;

  @Output() CloseModalFrw = new EventEmitter();

  constructor(private modalService: NgbModal, private authService: AuthService) {
    this.chckAcceptPlc = false;
  }

  ngOnInit(): void {}

  closeModalFrw(): void {
    this.CloseModalFrw.emit();
  }

}
