<div mat-dialog-actions id="mdlMeeting">




    <div >
        <div class="modal-header">
            <h3 class="modal-title modal_title" id="mdl-Meeting">Programar formulario con ID  {{message}}</h3>
            <!-- <button type="button" class="close" aria-label="Close" >
               <span aria-hidden="true">&times;</span>
               </button> -->
        </div>


        <div class="modal-body">
            <form class="frmMeeting">
                <div class="container">
                    <h3 class="modal-title modal_title" id="mdl-Meeting"></h3>
                    <div class="row">
                        <label for="example-datetime-local-input" class="col-4 col-form-label">Fecha inicio</label>
                        <div class="col-8">
                            <input placeholder="dd/mm/aaaa" type="date" name="date" id='date' #date_time="ngModel" [(ngModel)]="formProgram.newStructure[0].dateInit" [min]="minDate" [max]="maxDate">
                        </div>
                        <label for="example-datetime-local-input" class="col-4 col-form-label">Fecha fin</label>
                        <div class="col-8">
                            <input placeholder="dd/mm/aaaa" type="date" name="date" id='date' #date_time="ngModel" [(ngModel)]="formProgram.newStructure[0].dateEnd" [min]="minDate" [max]="maxDate">
                        </div>
                    </div>

                    <div class="row">
                     
                    </div>  
                    <div>
                        <mat-slide-toggle [checked] = "toogleCheckedP"  (change)="toggleCheckPeriodic($event)">Periodicidad</mat-slide-toggle>
                    </div> 
                    <div>
                        <mat-slide-toggle [checked] = "toogleCheckedS" (change)=" toggleCheckNoPeriodic($event)">Una sola vez</mat-slide-toggle>
                    </div> 
                    <div class="row" *ngIf="PereodicBoolP">
                        <div class="col-sm-10">
                            <div class="form-group">
                                <div class="col-4">
                                    <label for="pre-diagnosis" style="padding-top: 20px;">Días que se muestran formulario</label>
                                </div>
                                <div class="col-4">
                                    <input (click)="allChecks($event)" class="form-check-input check_input" type="checkbox" name="lunes" id='appointment' value="lunes">
                                    <label class="form-check-label check_label" for="exampleRadios1">Todos los días</label>
                                </div>                                

                                <div class="form-check ">
                                    <div class="col-4" style="display: inline-flex;">
                                        <input [(ngModel)]="formProgram.newStructure[0].days[1].check" class="form-check-input check_input" type="checkbox" name="lunes" id='appointment' value="lunes" [checked]="checks">
                                        <label class="form-check-label check_label" for="exampleRadios1">Lunes</label>
                                    </div>
                                    <div class="col-4" style="display: inline-flex;">
                                        <input [(ngModel)]="formProgram.newStructure[0].days[2].check" class="form-check-input check_input" type="checkbox" name="martes" id='appointment' value="martes"[checked]="checks">
                                        <label class="form-check-label check_label" for="exampleRadios2">Martes</label>
                                    </div>
                                    <div class="col-4" style="display: inline-flex;">
                                        <input [(ngModel)]="formProgram.newStructure[0].days[3].check" class="form-check-input check_input" type="checkbox" name="miercolest" id='appointment' value="miercoles"[checked]="checks">
                                        <label class="form-check-label check_label" for="exampleRadios1">Miércoles</label>
                                    </div>
                                    <div class="col-4" style="display: inline-flex;">
                                        <input [(ngModel)]="formProgram.newStructure[0].days[4].check" class="form-check-input check_input" type="checkbox" name="jueves" id='appointment' value="jueves"[checked]="checks">
                                        <label class="form-check-label check_label" for="exampleRadios2">Jueves</label>
                                    </div>
                                    <div class="col-4" style="display: inline-flex;">
                                        <input [(ngModel)]="formProgram.newStructure[0].days[5].check" class="form-check-input check_input" type="checkbox" name="viernes" id='appointment' value="viernes"[checked]="checks">
                                        <label class="form-check-label check_label" for="exampleRadios1">Viernes</label>
                                    </div>
                                    <div class="col-4" style="display: inline-flex;">
                                        <input [(ngModel)]="formProgram.newStructure[0].days[6].check" class="form-check-input check_input" type="checkbox" name="sabado" id='appointment' value="sabado"[checked]="checks">
                                        <label class="form-check-label check_label" for="exampleRadios2">Sábado</label>
                                    </div>
                                    <div class="col-4" style="display: inline-flex;">
                                        <input [(ngModel)]="formProgram.newStructure[0].days[7].check" class="form-check-input check_input" type="checkbox" name="domingo" id='appointment' value="domingo"[checked]="checks">
                                        <label class="form-check-label check_label" for="exampleRadios2">Domingo</label>
                                    </div>
                                </div>                      
                            </div>

                        </div>
                    </div>
                </div>
                <p class="msgform" id="msgform" #msgform></p>
            </form>
        </div>
        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
            <button mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="sendData()" >Programar</button>
        </div>      
    </div>
</div>