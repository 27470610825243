import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'frequency'
})
export class FrequencyPipe implements PipeTransform {

  transform(timeInHours: number): string {
    let transformed: string = '';
    let days = Math.trunc(timeInHours/24);
    let hours = timeInHours%24;
    if(days) {
      if(hours){
        transformed = `A las ${timeInHours} horas. (${days} días y ${hours} horas)`;
      }else{
        transformed = `A las ${timeInHours} horas. (${days} días)`;
      }
    }else{
      transformed = `A las ${timeInHours} horas.`;
    }
    return transformed;
  }

}
