import { Component, OnInit } from '@angular/core';
import { RespondentFormsService } from '../../services/respondent-forms.service';
import { ActivatedRoute } from '@angular/router';
import { RespondentForm } from '../../models//respondent_form.model';
import { AuthService } from '../../services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { globalData } from 'src/app/global/global_data';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-diagnosted',
  templateUrl: './diagnosted.component.html',
  styleUrls: ['./diagnosted.component.css']
})
export class DiagnostedComponent implements OnInit {
  private id: number;
  public respondentForm: RespondentForm;
  public aditionalForm;
  public structure: any;
  public structureFormAditional: any;
  private form: any;
  private currentTab: number;
  private tabs: any;
  public date; 
  public diagnosis: any;
  public pages: any;
  
  public segments: string[];
  public mdlMessage: any;
  public titleMessage: string;
  public message: string;
  public select = null;
  public warnin =false ;
  public diagnosisText;
  public Presuncion; 
  public Text;
  public resultPatient;
  dataSesion
  public urlPDF;
  public pathFileMngm;
  public  actualPatient: String;

  constructor(public sanitizer: DomSanitizer, private DataForm:RespondentFormsService,private rutaActiva: ActivatedRoute,private authService: AuthService,private modalService: NgbModal) { 
    this.dataSesion = this.authService.getDataSesion();
  }

  ngOnInit(): void {
    this.actualPatient = localStorage.getItem('patientSelected');
    this.pathFileMngm = this.sanitizer.bypassSecurityTrustResourceUrl(globalData.urls.fileURl+'/pdf/forms/') ;   
    console.log('this.pathFileMngm',this.pathFileMngm); 
    const { id } = this.rutaActiva.snapshot.params;
    this.id = id;
    this.GetForms(id);
    this.GetAditionalForm(id);
  }

  GetForms(id){
    this.DataForm.getByIdRespForm(id).subscribe(
      resp => {
        if (resp) {
          console.log('resp', resp);
          this.respondentForm = resp.resp[0];
          this.structure =  this.respondentForm.diagnostic_detail;
          this.resultPatient = resp.resp[0].result_patient         
          this.diagnosisText=resp.resp[0].diagnosis;
          this.Presuncion=resp.resp[0].presumption;
        }
      },
      error => {
        // //console.log(error);
      }
    );
  }

  
  GetAditionalForm(id){
    let idORginal = parseInt(id)
    let idFinal = idORginal + 1;
    this.DataForm.getByIdRespForm(idFinal).subscribe(
      resp => {
          if(resp.resp.length!=0){
            this.aditionalForm = resp.resp[0];
            console.log('respuesta aditional', this.aditionalForm)
            this.structureFormAditional = this.aditionalForm.structure
          }         
      },
      error => {
        // //console.log(error);
      }
    );
  }

  answerBuilder(lengthQ, answerNum): any {
    let arrAux: any = [];
    for (let i = 0; i < lengthQ; i++) {
      if (this.form[answerNum + i].checked) {
        const item = this.form[answerNum + i].value;
        arrAux.push(item);
      }
    }
    return arrAux;
  }

  onSubmit(): any {
    if(this.select== null){
      this.warnin=true;    
    }else{
      this.warnin=false;
      let answerNum: number = 0;
      this.form = document.getElementsByClassName('presenter');  
      this.structure.forEach( async (est: any, i) => {
        est.pages.forEach((pg, j) => {
          pg.forEach( async (q, n) => {
            let arrAux: any = [];
            let lengthQ = 1;  
            switch (q.type) {
              case 'Radio':
              case 'Checkbox':
                  lengthQ = q.items.length;
                  arrAux = this.answerBuilder(lengthQ, answerNum);
                break;
              default:
                  // this.diagnosis = this.form[answerNum].value;
                  // arrAux.push(this.diagnosis);
            }
            this.structure[i].pages[j][n]['diagnosis'] = arrAux;
            answerNum = answerNum + lengthQ;
          });
        });
      });

      const diagnosis: any = document.getElementById('diagnosis');
      const presumption: any = document.getElementById('presumption');

      this.respondentForm.custf_id = this.id;
      this.respondentForm.structure = this.structure;
      this.respondentForm['presumption'] = presumption.value;
      this.respondentForm['diagnosis'] = this.diagnosis;
      this.respondentForm['status'] = 'diagnosed';

    

    }
  }

  open(content,data) {
   this.urlPDF =globalData.urls.fileURl+'/pdf/forms/'+ data;
    // this.urlPDF = 'http://173.249.10.18:6630/pdf/forms/'  + data; 
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openMdlMeeting(): void {
 
    this.mdlMessage = document.getElementById('mdlMessage');
    this.mdlMessage.style.display = 'block';
  }

  closeMdlMessage(): void {
    this.mdlMessage = document.getElementById('mdlMessage');
    this.mdlMessage.style.display = 'none';
   
  }
  

}
