import { Component, OnInit, Inject } from '@angular/core';
import { CustomersFormsService } from '../../../services/customers-forms.service';
import { AuthService } from '../../../services/auth.service';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { element } from 'protractor';

@Component({
  selector: 'app-modal-select-my-qr',
  templateUrl: './modal-select-my-qr.component.html',
  styleUrls: ['./modal-select-my-qr.component.css']
})
export class ModalSelectMyQRComponent implements OnInit {
  dataSesion
  forms
  selected = null

  constructor(private customerService: CustomersFormsService,
              public dialogRef: MatDialogRef <ModalSelectMyQRComponent>,@Inject(MAT_DIALOG_DATA)public message:string,
              public authService: AuthService) { 

    this.dataSesion = this.authService.getDataSesion();

  }

  ngOnInit(): void {
    this.getDatabyIdCustomer()
  } 

  getDatabyIdCustomer(){
    this.customerService.getByIdCustForm(this.dataSesion.customerId).subscribe(res=>{ // poner id de canal this.dataSesion.customerId
      //console.log('Parse periodic',JSON.parse(res.resp[0].periodic))
      console.log('Customer_forms', res.resp);  
      this.forms = res.resp;     
    });
  }
  closeModal(){
    if(this.selected==''||this.selected==null||this.selected== ' '){
      alert('Debe seleccionar una opción');
    }else{
      let datafilter = this.forms.filter(element=> element.name = this.selected);
      let dataFilterEnd = datafilter[length-1];
            this.dialogRef.close(dataFilterEnd.name);
    }
    
   }
   
   quit(){
    this.dialogRef.close();     
   }


   Generar(){
     
   }

}
