
<button type="button" class="close" aria-label="Close" (click)="quit()" [routerLink]="['/ist-respondent-form']">
    <span aria-hidden="true">&times;</span>
</button>

<p class="text-center" style="color: #5288ec; font-size: 14px;">Selecciona un formulario para generar el código QR </p>
  <div class="form__group field">
    <label for="usr" class="form__label">Crear formulario </label>
    <mat-form-field appearance="fill">
      <mat-label>Formularios</mat-label>
      <mat-select [(ngModel)]="selected">
        <mat-option  *ngFor="let item of forms" [value]="item.name"> 
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>


  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
      <button mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="closeModal()">Generar</button>
  </div>
  
  