<div class="">

  <label>{{dtaQuestions.name}}</label>

  <div class="lbl form-check" *ngFor="let item of dtaQuestions.items;" >
    <input class="form-check-input check_input" type="checkbox" id="{{item}}" name="{{item}}" value="{{item}}">
    <label class="form-check-label check_label" for="{{item}}">{{item}}</label>
  </div>

  <div>
    <!-- <ul [ngSwitch]="component.type">
      <li *ngSwitchCase="'Text','number'">
          <app-text-number [dtaQuestions]="component"></app-text-number>
      </li>

      <li *ngSwitchCase="'Textarea'">
        <app-textarea [dtaQuestions]="component"></app-textarea>
      </li>

      <li *ngSwitchCase="'Radio'">
        <app-radio [dtaQuestions]="component"></app-radio>
      </li>

      <li *ngSwitchCase="'Checkbox'">
        <app-checkbox [dtaQuestions]="component"></app-checkbox>
      </li>

      <li *ngSwitchCase="'Combobox'">
        <app-combobox [dtaQuestions]="component"></app-combobox>
      </li>

      <li *ngSwitchCase="'Combobox'">
        <app-combobox [dtaQuestions]="component"></app-combobox>
      </li>

      <li *ngSwitchCase="'Email'">
        <app-email [dtaQuestions]="component"></app-email>
      </li>

      <li *ngSwitchCase="'File'">
        <app-file [dtaQuestions]="component"></app-file>
      </li>

      <li *ngSwitchCase="'Tel'">
        <app-tel [dtaQuestions]="component"></app-tel>
      </li>

      <li *ngSwitchCase="'Date'">
        <app-date [dtaQuestions]="component"></app-date>
      </li>

      <li *ngSwitchCase="'Image'">
        <app-image [dtaQuestions]="component"></app-image>
      </li>

    </ul> -->
  </div>

</div>
