import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-radio-complement',
  templateUrl: './radio-complement.component.html',
  styleUrls: ['./radio-complement.component.css']
})
export class RadioComplementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
