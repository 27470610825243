import { Component, OnInit } from '@angular/core';
import { CustomersFormsService } from '../../services/customers-forms.service';
import { CustomerForm } from '../../models/customer_form.model';
import { CustomersService } from '../../services/customers.service';
import { Users } from '../../models/users.model';
import { FomrsService } from '../../services/fomrs.service';
import { Form } from '../../models/forms.model';
import { SettingFomrsService } from '../../services/settings-fomrs.service';
import { SettingForm } from '../../models/setting_form.model';

@Component({
  selector: 'app-create-customer-form',
  templateUrl: './create-customer-form.component.html',
  styleUrls: ['./create-customer-form.component.css']
})
export class CreateCustomerFormComponent implements OnInit {

  public customerForm: CustomerForm;
  public customers: Users[];
  public forms: Form[];
  public settings: SettingForm[];
  public selectedForm: Form;
  public selectedSett: SettingForm;

  constructor(
    private customersFormsService: CustomersFormsService,
    private customersService: CustomersService,
    private fomrsService: FomrsService,
    private settingFomrsService: SettingFomrsService) {

    this.customerForm = {
      custf_id: null,
      name: null,
      description: null,
      structure: null,
      customer_id: null,
      form_id: null,
      setting_id: null,
      created_on: null
    };
   }

  ngOnInit(): void {
    this.getCustomers();
    this.getForms();
    this.getSetForms();
  }

  createCustForm(): void {
    this.customersFormsService.createCustForm(this.customerForm).subscribe(
      response => {
        if (response.correct) {
          //console.log(response);
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  getCustomers(): void {
    this.customersService.getCustomers().subscribe(
      response => {
        if (response.correct) {
          this.customers = response.resp;
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  getForms(): void {
    this.fomrsService.getForms().subscribe(
      response => {
        if (response.correct) {
          this.forms = response.resp;
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  getSetForms(): void {
    this.settingFomrsService.getSetForms().subscribe(
      response => {
        if (response.correct) {
          this.settings = response.resp;
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  findForm(id, pool): any {
    const selected: any = pool.find((item) => {
      let idPool: number;
      if (item.form_id) {
        idPool = parseInt(`${item.form_id}`);
      }
      if (item.cfc_id) {
        idPool = parseInt(`${item.cfc_id}`);
      }
      return idPool === parseInt(id);
    });
    return selected;
  }

  findSegment(structForm, item): any {
    const segment = structForm.segments.find((e) => {
      return e.name === item;
    } );
    return segment;
  }

  pagesBuilder(structSett, structForm, index): any {
    let arrPages = [];
    structSett.pags_segs[index].forEach(page => {
      let arrPage = [];
      structSett.quest_pags[page].forEach(question => {
        arrPage.push(structForm.questions[question]);
      });
      arrPages.push(arrPage);
    });
    return arrPages;
  }

  structureBuilder(structSett, structForm): any {
    const strsucture = structSett.sequence.map((item, index) => {
      const segment = this.findSegment(structForm, item);
      const name = segment.name;
      const description = segment.description;
      const role = structSett.roles_seg[index][0];
      const pages = this.pagesBuilder(structSett, structForm, index);
      const data = { name, description, role, pages };
      return data;
    });
    return strsucture;
  }

  onSubmit(): any {
    this.selectedForm = this.findForm(this.customerForm.form_id, this.forms);
    this.selectedSett = this.findForm(this.customerForm.setting_id, this.settings);
    const structForm = this.selectedForm.structure;
    const structSett = this.selectedSett.structure;
    const strsucture = this.structureBuilder(structSett, structForm);
    this.customerForm.name = this.selectedForm.name;
    this.customerForm.description = this.selectedForm.description;
    this.customerForm.structure = strsucture;
    this.createCustForm();
  }

}
