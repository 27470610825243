import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MeetingService} from '../../../services/meeting.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


 



@Component({
  selector: 'app-modal-meet-conf',
  templateUrl: './modal-meet-conf.component.html',
  styleUrls: ['./modal-meet-conf.component.css']
})
export class ModalMeetConfComponent implements OnInit {

  @Output() ConfirmMeeting = new EventEmitter();

  @Output() CloseMdlConfMeeting = new EventEmitter();
  Text='Le agradecemos que de click para confirmar su cita: '
  public textFecha= ''
  public textHora= ''
  public meetings: any;
  public timeZone;



  constructor(private router: Router, private meetingService: MeetingService,
    public dialogRef: MatDialogRef <ModalMeetConfComponent>,@Inject(MAT_DIALOG_DATA)public message:string) {

    this.meetings = [];
   }

  ngOnInit(): void { 

    
    setTimeout(()=>{ this.getMeetings();}, 900);  
          

  }

  confirmMeeting(data): void {
    // this.ConfirmMeeting.emit(data);
    this.meetingService.StatusMeeting(data)
    this.dialogRef.close('Aceptado')
    this.Text='Cita aceptada ✔️';
    setTimeout(()=>{  
      location.reload();   
      this.router.navigate(['/login']);       
 }, 3000);   
  }


  closeMdlConfMeeting(): void {
  
    // this.CloseMdlConfMeeting.emit();
    this.dialogRef.close()
  }

  getMeetings(): void {
    this.meetingService.getMeetings().subscribe(
      resp => {       
        if (resp.correct) {
          //console.log('responseGetMeetings',resp)               
           this.textFecha = 'Fecha: '+ this.meetingService.Date; 
           this.textHora = 'Hora: ' + this.meetingService.Time; 
           this.timeZone = this.meetingService.Zone
          //console.log('resp', this.textFecha,this.textHora);
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  
}
