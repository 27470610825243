<nav *ngIf="showNav" class="navbar navbar-expand-bl " style="background: #00669A;">
    <div class="container-fluid">

        <div (click)="sidebarStatus()" class=" pointer">
            <span *ngIf="sidebarStt" class="material-icons" style="color: rgb(255, 255, 255); background: #00669A;">
            menu_open
        </span>
            <span *ngIf="!sidebarStt" class="material-icons" style="color: #ffffff; background: #00669A;">
                menu
        </span>
        </div>
        <div class="nav-link pointer" style="cursor: pointer;" (click)="signOut()" style="color: #ffffff; margin-left: -10%;">Cerrar sesión
            <span class="material-icons" style="position: absolute; margin-top: -0.3%; margin-left: 0.3%;">
            login
        </span>
        </div>
        <!-- <ul class="">
            <li class="nav-item" *ngIf="session">
            </li>
        </ul> -->
    </div>
</nav>