
  <mat-dialog-content class="mat-typography">
    <pdf-viewer src="{{urlPDF}}"  [render-text-mode]="0"></pdf-viewer>
    <!-- <ngx-extended-pdf-viewer   [(src)]="urlPDF"  -->
    <!-- style="height: 100px;" -->
     <!-- [showPrintButton]="true" [showBookmarkButton]="false" -->
     <!-- [showOpenFileButton]="false"  -->
     <!-- [height]="'100vh'" -->
     <!-- [zoom]= 50 -->
     <!-- delayFirstView="1000" useBrowserLocale="true"></ngx-extended-pdf-viewer> -->
  </mat-dialog-content>
 <!-- <div>
    <pdf-viewer src="{{urlPDF}}"[autoresize]="true"
   
    ></pdf-viewer>
 </div> -->

                                                        <!-- <iframe src="{{urlPDF}}" width="800"
                                                        height="800"  frameborder="0"></iframe> -->

    

  