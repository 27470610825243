
<!-- The Modal -->

  <!-- Modal content -->
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title modal_title" id="mdl-prvplc">Política de protección de datos</h3>  
    </div>

    <div class="modal-body">
      <form >
        <div class="container">
          <textarea rows="15" disabled>
            {{politics}}
          </textarea>
        </div>
      </form>
    </div>

    <div class="form-check">
      <div style="display: inline-flex;">
        <input class="form-check-input check_input" type="checkbox" id="chck_acceptPlc" name="chck_acceptPlc">
        <label class="form-check-label check_label" for="chck_acceptPlc">He leído y acepto</label>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-secondary btn-sml" (click)="closeModal()">Rechazar</button>
      <button type="button" class="btn btn-info btn-sml" id="btn_accept" (click)="privacyPolicies($event,'accept')">Aceptar</button>
    </div>

  </div>


