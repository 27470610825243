import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { SettingFomrsService } from '../../services/settings-fomrs.service';
import { SettingForm } from '../../models/setting_form.model';
import { ParametersService } from '../../services/parameters.service';
import { Parameters } from '../../models/parameters.model';
import { FomrsService } from '../../services/fomrs.service';
import { Form } from '../../models/forms.model';

@Component({
  selector: 'app-create-setting-form',
  templateUrl: './create-setting-form.component.html',
  styleUrls: ['./create-setting-form.component.css']
})
export class CreateSettingFormComponent implements OnInit {

  private form: any;
  private currentTab: number;
  private tabs: any;
  public formsData: Form[];
  public setFormData: SettingForm;
  public showQuest: boolean;
  public selectedForm: Form;
  public parameters: Parameters[];
  public roles: Parameters[];
  public segments: string[];

  constructor(
    private formsServices: FomrsService,
    private settingFomrsService: SettingFomrsService,
    private parametersService: ParametersService) {
    this.form = document.getElementById('crtSettingForm');
    this.currentTab = 0;
    this.setFormData = {
      cfc_id: null,
      name: null,
      description: null,
      structure: null,
      form_id: null,
      created_on: null
    };
    this.tabs = document.getElementsByClassName('tab');
    this.showQuest = false;
  }

  ngOnInit(): void {
    this.getForms();
    this.getParameters();
    this.showTab(this.currentTab);
  }

  getForms(): void {
    this.formsServices.getForms().subscribe(
      response => {
        if (response.correct) {
          this.formsData = response.resp;
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  createSetForm(): void {
    this.settingFomrsService.createSetForm(this.setFormData).subscribe(
      response => {
        if (response.correct) {
          //console.log(response);
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  rolesBuilder(item): any {// filter
    if (parseInt(item.type) === 1) {
      return item;
    }
  }

  segmentsBuilder(item): any {
    return item.name;
  }

  getParameters(): void {
    this.parametersService.getParameters().subscribe(
      response => {
        if (response.correct) {
          this.parameters = response.resp;
          this.roles = this.parameters.map(this.rolesBuilder);
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  validateQuestion(p,q): any {
    // //console.log(`${p}, ${q}`);
    // let q_slc = document.getElementsByClassName('q_slc');
    // //console.log(q_slc);
  }

  validatePage(p,q): any {
    // //console.log(`${p}, ${q}`);
    // let pg_slc = document.getElementsByClassName('pg_slc');
    // //console.log(pg_slc);
  }

  findForm(id): any {
    const selectedForm: any = this.formsData.find((item) => {
      return parseInt(`${item.form_id}`) === parseInt(id);
    });
    return selectedForm;
  }

  showTab(n): void {

    this.tabs[n].style.display = 'block';

    if (n === 1 && this.setFormData.form_id) {
      this.selectedForm = this.findForm(this.setFormData.form_id);
      if (this.selectedForm) {
        this.segments = this.selectedForm.structure.segments.map(this.segmentsBuilder);
        this.showQuest = true;
      }
    }

    if (n === 0) {
      document.getElementById('prevBtn').style.display = 'none';
    } else {
      document.getElementById('prevBtn').style.display = 'inline';
    }
    if (n === (this.tabs.length - 1)) {
      document.getElementById('nextBtn').innerHTML = 'Submit';
    } else {
      document.getElementById('nextBtn').innerHTML = 'Next';
    }

    this.fixStepIndicator(n);
  }

  nextPrev(n): boolean {
    this.tabs[this.currentTab].style.display = 'none';

    if (this.currentTab < (this.tabs.length - 1)) {
      if (n === 1 && !this.validateForm()) {
        return false;
      }
      this.currentTab = this.currentTab + n;
    } else {
      this.onSubmit();
      // this.crtSettingForm.reset();
      this.currentTab = 0;
    }

    this.showTab(this.currentTab);
  }

  validateForm(): boolean {
    //   // This function deals with validation of the form fields
    //   let x, y, i, valid = true;
    //   x = document.getElementsByClassName('tab');
    //   y = x[currentTab].getElementsByTagName('input');
    //   // A loop that checks every input field in the current tab:
    //   for (i = 0; i < y.length; i++) {
    //     // If a field is empty...
    //     if (y[i].value === '') {
    //       // add an 'invalid' class to the field:
    //       y[i].className += ' invalid';
    //       // and set the current valid status to false
    //       valid = false;
    //     }
    //   }
    //   // If the valid status is true, mark the step as finished and valid:
    //   if (valid) {
    //     document.getElementsByClassName('step')[currentTab].className += ' finish';
    //   }
    //   return valid; // return the valid status

      return true;
    }

    fixStepIndicator(n): void {
      const x = document.getElementsByClassName('step');
      let i;

      for (i = 0; i < x.length; i++) {
        x[i].className = x[i].className.replace(' active', '');
      }

      x[n].className += ' active';
    }

    /* Drag and drop */
    drop(event: CdkDragDrop<string[]>): void {
      moveItemInArray(this.segments, event.previousIndex, event.currentIndex);
      //console.log(this.segments);
    }

    /* Builder Structure */

    arrIndexSelect(data): any {

      const dataLength: any = data.length;

      let arrIndexSelect = [];

      for (let i = 0; i < dataLength; i++) {
        const dataL: any = data[i].length;
        let arrItem = [];
        for (let j = 0; j < dataL; j++) {
          const element = data[i][j];
          if (element.selected === true) {
            arrItem.push(element.index);
          }
        }
        arrIndexSelect.push(arrItem);
      }
      return arrIndexSelect;
    }

    buildStructure(): any {

      const objQuestPage: any = document.getElementsByClassName('q_slc');
      const objPagSegment: any = document.getElementsByClassName('pg_slc');
      const objRolSegment: any = document.getElementsByClassName('r_slc');

      const questPage = this.arrIndexSelect(objQuestPage);
      const pagSegment = this.arrIndexSelect(objPagSegment);
      const rolSegment = this.arrIndexSelect(objRolSegment);

      const structure = {
          sequence: this.segments,
          roles_seg: rolSegment,
          pags_segs: pagSegment,
          quest_pags: questPage
      };

      return structure;
    }

    onSubmit(): any {
      const structure: any = this.buildStructure();
      this.setFormData.structure = structure;
      //console.log(this.setFormData);
      this.createSetForm();
    }

}
