import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-modal-confirm-data',
  templateUrl: './modal-confirm-data.component.html',
  styleUrls: ['./modal-confirm-data.component.css']
})
export class ModalConfirmDataComponent implements OnInit {
  public dataSession;

  constructor(public dialogRef: MatDialogRef <ModalConfirmDataComponent >,@Inject(MAT_DIALOG_DATA)public message:string,
              private router: Router, public authservice:AuthService) { }

  ngOnInit(): void {
    this.dataSession = this.authservice.getDataSesion();
  }
  Confirm(){
    if(this.dataSession.role == 'doctor' || this.dataSession.role == 'specialist' && this.dataSession.logical_medical=='cardiology'){
      this.router.navigate(['/medical-dashboard']);
      this.dialogRef.close();
    }else{
      this.router.navigate(['/list-respondent-form']);
      this.dialogRef.close();
    }
    
  }
  closeModal(){
    this.dialogRef.close();
  }

}
