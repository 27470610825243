import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css']
})
export class TextareaComponent implements OnInit {

  @Input() public dtaQuestions: any;
  @Output() AddRmvAnswered = new EventEmitter();

  @HostListener('change', ['$event'])
  onChange(event: any): void {
    const item: any = event.srcElement;
    const itemLength = item.length;
    this.addRmvAnswered(itemLength, event.target.name);
  }

  constructor() { }

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    const attributes = this.dtaQuestions.attributes;
    const required = attributes.find(element => element === 'required');

    if (required) {
      const question_label = document.getElementById(`lbl-${this.dtaQuestions.name}`);
      question_label.setAttribute("required", "true");
      question_label.setAttribute("answered", "false");
    }

  }

  addRmvAnswered(counter: number, lblName: string): void {
    let answered = false;
    if (counter !== 0) {
      answered = true;
    }
    //console.log('lblNAme',lblName);

    this.AddRmvAnswered.emit({
      question: lblName,
      answered
    });
  }


}
