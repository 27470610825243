import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { ConsoleReporter } from 'jasmine';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public session: boolean;
  public sidebarStt: boolean;
  public showNav = true;

  constructor(private authService: AuthService, private router: Router) {
    if (screen.width < 768 && localStorage.getItem('MyQr')=='true'){
      this.showNav=false
    }else{
      this.showNav = true;
    }
    //     document.write ("Pequeña") 
    this.session = false;
   
    let width = screen.width;
    if(width <= 768){
      this.sidebarStt = false;
    }else{
      this.sidebarStt = true;
    }
  }

  ngOnInit(): void {
    this.loggedIn();
    this.sidebarDeplCol();
    this.viewChange();
   
   
  }

  viewChange(){
    let width = screen.width;
    setTimeout(()=>{
       if( localStorage.getItem('MyQrPage')=='false'){         
        this.showNav = true;      
       }
      if( localStorage.getItem('MyQrPage')=='true' && width <= 768){     
        this.showNav = false;
   
        this.sidebarCollapse()     
           
       }
       this.viewChange();          
    }, 100);      
  }

 


  loggedIn(): boolean {
    const dataSesion = this.authService.getDataSesion();
    if (dataSesion.token) {
      this.session = true;
    }
    return this.session;
  }

  sidebarDeployment(): void {
    const sidebar = document.getElementById('sidebar');
    const spans: any = document.getElementsByClassName('btn_SSttSpan');
    const spansLength = spans.length;

    sidebar.classList.remove('sidebar_collapse');

    for (let i = 0; i < spansLength; i++) {
      spans[i].classList.remove('span_call');
    }

  }

  sidebarCollapse(): void {
    const sidebar = document.getElementById('sidebar');
    const spans: any = document.getElementsByClassName('btn_SSttSpan');
    const spansLength = spans.length;

    sidebar.classList.add('sidebar_collapse');

    for (let i = 0; i < spansLength; i++) {
      spans[i].classList.add('span_call');
    }
  }

  sidebarDeplCol(): void {   
    if (this.sidebarStt) {
      this.sidebarDeployment();
    } else {
      this.sidebarCollapse();
    }
  }

  sidebarStatus(): void {
    if (this.sidebarStt) {
      this.sidebarStt = false;
    } else {
      this.sidebarStt = true;
    }
    this.sidebarDeplCol();
  }

  signOut(): void {
    this.authService.signOut();
  }

}
