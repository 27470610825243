import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-external-services',
  templateUrl: './external-services.component.html',
  styleUrls: ['./external-services.component.css']
})
export class ExternalServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
