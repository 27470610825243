
<div style="position: absolute; width: 85%; height: 100%;background: #e6e6e6; z-index: 999999; opacity: 0.3; margin-left: 0%;">
</div>
<div class="container-fluid">
    <div class="container-fluid">

        <div class="row">

            <div class="card col-sm-12">

                <div class="overflow-auto card-body">
                    <p class="titel_form">{{respondentForm.name}}</p>

                    <form class="respondent_form" id="editRespondentForm" #editRespondentForm="ngForm" (ngSubmit)="onSubmit()">

                        <section *ngFor="let segment of structure; let i = index;">

                            <div *ngFor="let page of segment.pages; let j = index;">

                                <div *ngIf="i == 0 && j == 0; then firstDisplay else followingDisplay"></div>

                                <ng-template #firstDisplay>
                                    <div class="tab tab0">
                                        <p class="desc_form">{{respondentForm.description}}</p>
                                        <h3 class="titel_seg" *ngIf="j == 0;">{{segment.name}}</h3>

                                        <ul>
                                            <li class="li_question" *ngFor="let question of page;">

                                                <ul [ngSwitch]="question.type">
                                                    <li *ngSwitchCase="'Text'">
                                                        <app-text-number [dtaQuestions]="question" [index]="j"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Number'">
                                                        <app-text-number [dtaQuestions]="question"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Textarea'">
                                                        <app-textarea [dtaQuestions]="question"></app-textarea>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio'">
                                                        <app-radio [dtaQuestions]="question"></app-radio>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox'">
                                                        <app-checkbox [dtaQuestions]="question"></app-checkbox>
                                                    </li>
                                                    <li *ngSwitchCase="'Combobox'">
                                                        <app-combobox [dtaQuestions]="question"></app-combobox>
                                                    </li>
                                                    <li *ngSwitchCase="'Email'">
                                                        <app-email [dtaQuestions]="question"></app-email>
                                                    </li>
                                                    <li *ngSwitchCase="'File'">
                                                        <app-file [dtaQuestions]="question"></app-file>
                                                    </li>
                                                    <li *ngSwitchCase="'Tel'">
                                                        <app-tel [dtaQuestions]="question"></app-tel>
                                                    </li>
                                                    <li *ngSwitchCase="'Datepicker'">
                                                        <app-date [dtaQuestions]="question"></app-date>
                                                    </li>
                                                    <li *ngSwitchCase="'Image'">
                                                        <app-image [dtaQuestions]="question"></app-image>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio-complement'">
                                                        <app-radio-complement [dtaQuestions]="question"></app-radio-complement>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox-complement'">
                                                        <app-checkbox-complement [dtaQuestions]="question"></app-checkbox-complement>
                                                    </li>

                                                </ul>

                                            </li>

                                        </ul>

                                    </div>

                                </ng-template>

                                <ng-template #followingDisplay>
                                    <div class="tab">
                                        <h3 class="titel_seg" *ngIf="j == 0;">{{segment.name}}</h3>

                                        <ul>
                                            <li class="li_question" *ngFor="let question of page;">

                                                <ul [ngSwitch]="question.type">
                                                    <li *ngSwitchCase="'Text'">
                                                        <app-text-number [dtaQuestions]="question"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Number'">
                                                        <app-text-number [dtaQuestions]="question"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Textarea'">
                                                        <app-textarea [dtaQuestions]="question"></app-textarea>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio'">
                                                        <app-radio [dtaQuestions]="question"></app-radio>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox'">
                                                        <app-checkbox [dtaQuestions]="question"></app-checkbox>
                                                    </li>
                                                    <li *ngSwitchCase="'Combobox'">
                                                        <app-combobox [dtaQuestions]="question"></app-combobox>
                                                    </li>
                                                    <li *ngSwitchCase="'Email'">
                                                        <app-email [dtaQuestions]="question"></app-email>
                                                    </li>
                                                    <li *ngSwitchCase="'File'">
                                                        <app-file [dtaQuestions]="question"></app-file>
                                                    </li>
                                                    <li *ngSwitchCase="'Tel'">
                                                        <app-tel [dtaQuestions]="question"></app-tel>
                                                    </li>
                                                    <li *ngSwitchCase="'Datepicker'">
                                                        <app-date [dtaQuestions]="question"></app-date>
                                                        <!-- <label id="lbl-{{question.name}}" class="lbl lbl-question"for="{{question.name}}">{{question.name}}</label>
                                                        <input placeholder="dd/mm/aaaa" type="date" name="date" id='date' [(ngModel)]="date">                                                         -->
                                                    </li>
                                                    <li *ngSwitchCase="'Image'">
                                                        <app-image [dtaQuestions]="question"></app-image>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio-complement'">
                                                        <app-radio-complement [dtaQuestions]="question"></app-radio-complement>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox-complement'">
                                                        <app-checkbox-complement [dtaQuestions]="question"></app-checkbox-complement>
                                                    </li>
                                                </ul>

                                            </li>

                                        </ul>

                                    </div>
                                </ng-template>

                            </div>

                        </section>

                        <div style="text-align:center;margin-top:40px;">
                            <span class="step" *ngFor="let pag of pages;"></span>
                        </div>

                    </form>
                </div>

            </div>

            <div class="card col-sm-12">
                <div class="form-group">
                    <label for="diagnosis" style="padding-top: 20px;">Anamnesis:</label>
                    <textarea placeholder="{{diagnosisText}}" disabled [(ngModel)]="diagnosis" class="form-control" id="diagnosis" rows="8"></textarea>
                </div>
            </div>

            <div class="card col-sm-12">
                <div class="form-group">
                    <label for="presumption" style="padding-top: 20px;">Presunción diagnóstica:</label>
                    <textarea placeholder="{{Presuncion}}" disabled [(ngModel)]="diagnosis" class="form-control" id="diagnosis" rows="3"></textarea>
                    <div *ngIf="warnin">
                        <label style="color: red;"> Eliga una opcion*</label>
                    </div>
                </div>
            </div>


        </div>

    </div>
</div>