<p class="titel_form">Formularios de clientes</p>

<a class="btn btn-info" role="button" [routerLink]="['/create-customer-form']">Crear</a>
<div class="table-responsive">
  <table class="table">
    <thead class="" style="background: #00669A;">
      <tr>
        <th style="color: #fff;">Id</th>
        <th style="color: #fff;">Estructura</th>
        <th style="color: #fff;">Id Cliente</th>
        <th style="color: #fff;">Id Form.</th>
        <th style="color: #fff;">Id Config.</th>
        <th style="color: #fff;">Creación</th>
        <th style="color: #fff;">Acciones</th>
        <th style="color: #fff;">Periodo</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let form of custFormData">
        <th>{{form.custf_id}}</th>
        <textarea rows="5"cols="30">{{dataStructure}}</textarea>        
        <td>{{form.customer_id}}</td>
        <td>{{form.form_id}}</td>
        <td>{{form.setting_id}}</td>
        <td>{{form.created_on}}</td>
        <td><a class="btn btn-info" role="button" [routerLink]="['/edit-setting-form', form.form_id]">Editar</a></td>
        <td><a class="btn btn-info" role="button" (click)="openModalPereodicForms(form.customer_id)" style="color: #fff;">Programar</a></td>
      </tr>
    </tbody>
  </table>
</div>

