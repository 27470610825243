import { Component, OnInit, Renderer2, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FomrsService } from '../../services/fomrs.service';
import { Form } from '../../models/forms.model';

@Component({
  selector: 'app-create-forms',
  templateUrl: './create-forms.component.html',
  styleUrls: ['./create-forms.component.css']
})
export class CreateFormsComponent implements OnInit {

  private numQuestion: number;
  private segments: any;
  private pages: any;
  private questions: any;
  public form: Form;
  public titel: string = 'Crear Formulario';
  @ViewChild('divSegments') divSegments: ElementRef;
  @ViewChild('divPages') divPages: ElementRef;
  @ViewChild('divQuestions') divQuestions: ElementRef;
  @ViewChild('divItems') divItems: ElementRef;
  @HostListener('click', ['$event'])
  onClick(event: any): void {
    if (event.target.id === 'btn_addItem') {
      this.addItem(event);
    }
  }

  constructor(private renderer: Renderer2, private el: ElementRef, private formsServices: FomrsService) {
    this.form = {
      form_id: null,
      name: null,
      description: null,
      structure: null,
      status: null,
      created_on: null
    };
    this.numQuestion = 0;
    this.segments = [];
    this.pages = [];
    this.questions = [];
   }

  ngOnInit(): void { }

  addSegment(): void {
    const div: HTMLParagraphElement = this.renderer.createElement('div');
    div.innerHTML = `<div class="row">
                        <div class="col">
                          <label class="title_input">Nombre</label>
                          <input type="text" class="">
                        </div>
                        <div class="col">
                          <label class="title_input">Descripción</label>
                          <input type="text" class="">
                        </div>
                    </div>`;
    this.renderer.appendChild(this.divSegments.nativeElement, div);
  }

  addPage(): void {
    const div: HTMLParagraphElement = this.renderer.createElement('div');
    div.innerHTML = 'add Page';
    this.renderer.appendChild(this.divPages.nativeElement, div);
  }

  addQuestion(): void {
    const div: HTMLParagraphElement = this.renderer.createElement('div');// number email file date image
    div.innerHTML = `<div class="row">
                        <div class="col-md-4">
                          <label class="title_input">Nombre</label>
                          <input type="text" class="" placeholder="">
                        </div>
                        <div class="col-md-4">
                          <label class="title_input">Descripción</label>
                          <input type="text" class="" placeholder="">
                        </div>
                        <div class="col-md-3">
                          <label class="title_input">Tipo</label>
                          <select id="slcType" class="">
                            <option value="" disabled selected></option>
                            <option value="Text">Texto</option>
                            <option value="Textarea">Texto Amplio</option>
                            <option value="number">Número</option>
                            <option value="Email">Correo</option>
                            <option value="Radio">Selección Simple</option>
                            <option value="Combobox">Selc. Simple Desplegable</option>
                            <option value="Radio-complement">Selc. Simple Comp.</option>
                            <option value="Checkbox">Selección Múltiple</option>
                            <option value="Checkbox-complement">Selc. Múltiple Comp.</option>
                          </select>
                        </div>
                        <div class="col-md-1">
                          <label class="title_input">Items</label>
                          <button type="button" class="btn btn-outline-info btn-sm btn_addItem" id="btn_addItem" title="${this.numQuestion}"><i class="fa fa-plus-circle" id="btn_addItem" title="${this.numQuestion}"></i></button>
                        </div>

                        <div class="col-md-12">
                              <div id="divItems${this.numQuestion}" style="display: inline"></div>
                        </div>

                    </div>

                    <hr>`;
    this.renderer.appendChild(this.divQuestions.nativeElement, div);
    this.numQuestion = this.numQuestion + 1;
  }

  addItem(event): void {
    let divItems: any;
    let id: number;
    if (event.target.title) {
      id = parseInt(event.target.title) ;
    } else {
      id = parseInt(event.target.parentElement.title);
    }

    const item = `<div class="col-md-12">
                    <label>Item</label>
                    <input type="text" class="items${id}">
                  </div>`;

    divItems = document.getElementById(`divItems${id}`);
    divItems.insertAdjacentHTML('beforebegin', item);

  }

  createForm(): void {
    this.formsServices.createForm(this.form).subscribe(
      response => {
        if (response.correct) {
          //console.log(response.resp);
        }
      },
      error => {
        //console.log(error);
      }
    );
    // limpiar formulario
  }

  onSubmit(): any {
    const segmentsRows: any = document.getElementById('segments').querySelectorAll('.row');
    const questionsRows: any  = document.getElementById('questions').querySelectorAll('.row');
    const pagesNum: any = document.getElementById('pages');

    for (let i = 0; i < segmentsRows.length; i++) {
      const seg = segmentsRows[i];
      const name: string = seg.children[0].children[1].value;
      const description: string = seg.children[1].children[1].value;
      const segment: any = {
        name,
        description
      };
      this.segments.push(segment);
    }

    for (let i = 0; i < questionsRows.length; i++) {
      const quest: any = questionsRows[i];
      const itemsRows: any = quest.children[4].children;
      const name: string = quest.children[0].children[1].value;
      const description: string = quest.children[1].children[1].value;
      const type: string = quest.children[2].children[1].value;
      let items: any = [];

      for (let i = 0; i < itemsRows.length - 1 ; i++) {
        const item = itemsRows[i].children[1].value;
        items.push(item);
      }

      const question: any = {
        name,
        description,
        type,
        items
      }

      this.questions.push(question);

    }

    for (let i = 0; i < pagesNum.value; i++) {
      this.pages.push([]);
    }

    const structure = {
      segments: this.segments,
      pages: this.pages,
      questions: this.questions
    };

    this.form.structure = structure;

    //console.log(structure);
    this.createForm();
  }

}
