import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(orgDate: string, format: string): string {
    const frmDate = new Date(parseInt(orgDate));
    return this.datePipe.transform(frmDate, format);
  }
}
