import { Component, OnInit, ViewChild, HostListener  } from '@angular/core';
import { Router } from '@angular/router';
import { RespondentFormsService } from '../../services/respondent-forms.service';
import { RespondentForm } from '../../models//respondent_form.model';
import { AuthService } from '../../services/auth.service';
import { UsersService } from '../../services/users.service';
import { MeetingService } from '../../services/meeting.service';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { ModalMeetingsComponent } from '../_presenters/modal-meetings/modal-meetings.component';
import { LoaderComponent } from '../_presenters/loader/loader.component';
import { CustomersService } from '../../services/customers.service';
import { ParametersService } from '../../services/parameters.service';

@Component({
  selector: 'app-medical-dashboard',
  templateUrl: './medical-dashboard.component.html',
  styleUrls: ['./medical-dashboard.component.css']
})
export class MedicalDashboardComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  public dataSesion: any;
  public users: any;
  public meetings: any;
  public meetingsScheduled: any;
  public meetingsRescheduled: any;
  public meetingsAttended: any;
  public onholds: any;
  public respForms: RespondentForm[];
  public respFormsUsablity: any;
  public dataFilter: any;
  public dataMeeting: any;
  public dataReschedule: any;
  public dataEspecialistRespondent:any;
  public dataDiagnosticRespondent:any;
  public dataUsabalityRespondent: any;
  public dataDiagnosed: any;
  public headersFilter: any;
  public headerEspecialistFirstTab:any;
  public headerEspecialistRespondent:any;
  public headerDiagnostedRespondent:any
  public headerUsability:any;
  public headersMeeting: any;
  public headersReschedule: any;
  public headersDiagnosed: any;
  public title: string;
  public riskSigns: any;
  public affirmativeRisk: any;
  public affirmativeQuestions: any;
  public riskRanges: any;
  public mdlMeeting: any;
  private riskPointsRanges: any;
  private dataCrtMeeting: any;
  private dataCrtRecheduled: any
  public dataConfMeet: any;
  public dataRescheduled: any;
  public dataConfirm;
  public tab ='Pacientes por ver' ;
  public appMeetModal = true;
  public status;
  public noRowsTemplate;
  public loadingTemplate;
  public dateTime = [];
  public mt_id;
  public dfh_id
  public url;
  public zoomKey;
  public meetingUserId;
  public timeZone ;
  public zone ;
  public data
  public dataFilterOnhold;
  public dataFilterAnswered;
  public clinicsData:any ;
  public loadGrid = false;


  Notify = false;
  @HostListener('click', ['$event'])
  onClick(event: any): void {
    //console.log('event.target.id',event);
    switch (event.target.id) {      
      case 'btn_mdlZoom':
          this.dataCrtMeeting = {
            resp_id: event.target.attributes.respondents_id.value,
            dfh_id: event.target.attributes.dfh_id.value,
            usr_id: this.dataSesion.id ,
            intPhoneCode: this.dataSesion.intPhoneCodes,//cambiar zona horaria aquí
            key_zoom: null,
            customer_id: null
          };
          this.dataRescheduled = {
            type: 'status',
            id: event.target.attributes.dfh_id.value,
            status: 'rescheduled'
          };          
          this.openDialog();
          localStorage.setItem('patientSelected',event.target.text);
        break;
      case 'btn_meeting':
          const dfh_id = event.target.attributes.dfh_id.value;
          const routeInit = `/zoom-meeting/${dfh_id}`;
          this.router.navigate([routeInit]);
          localStorage.setItem('patientSelected',event.target.text);
        break;
      case 'btn_mdlRescheduled':     
        this.dataRescheduled = {
          type: 'status',
          id: event.target.attributes.dfh_id.value,
          status: 'rescheduled'
        };
        //console.log('this.dataRescheduled',this.dataRescheduled);
        this.mt_id=event.target.attributes.dfh_id.value
        // reset form
        localStorage.setItem('patientSelected',event.target.text);
      break;
      case 'btn_diagnosed':
        const dfh_id_ = event.target.attributes.dfh_id_.value;
        const routeInit_ = `diagnosted/${dfh_id_}`;
        //console.log('routeInit_',routeInit_);
        this.router.navigate([routeInit_]);
        localStorage.setItem('patientSelected',event.target.text);
      break;
      case 'btn_specialistRespondentFirstTab':
        //console.log('***************',event.target.attributes);
        this.dataCrtMeeting = {
          resp_id: event.target.attributes.respondents_id.value,
          dfh_id: event.target.attributes.dfh_id.value,
          usr_id: this.dataSesion.id ,
          intPhoneCode: this.dataSesion.intPhoneCodes,//cambiar zona horaria aquí
          key_zoom: null,
          customer_id: null
        };       
        this.openSpecialistRespondtFirstTab(this.dataCrtMeeting);
        localStorage.setItem('pendienteFlujo','false');
        console.log('event.target.attributes----->',event.target.text);
        localStorage.setItem('patientSelected',event.target.text);

      break;      
      case 'btn_specialistRespondentSecondTab':
        //console.log('***************',event.target.attributes);
        this.dataCrtMeeting = {
          resp_id: event.target.attributes.respondents_id.value,
          dfh_id: event.target.attributes.dfh_id.value,
          usr_id: this.dataSesion.id ,
          intPhoneCode: this.dataSesion.intPhoneCodes,//cambiar zona horaria aquí
          key_zoom: null,
          customer_id: null
        };        
        this.openSpecialistRespondtSecondTab(this.dataCrtMeeting);
        localStorage.setItem('pendienteFlujo','true');
        localStorage.setItem('patientSelected',event.target.text);
      break;
      case 'btn_utility':
        //console.log('***************',event.target.attributes);
        this.dataCrtMeeting = {
          resp_id: event.target.attributes.respondents_id.value,
          dfh_id: event.target.attributes.dfh_id.value,
          usr_id: this.dataSesion.id ,
          intPhoneCode: this.dataSesion.intPhoneCodes,//cambiar zona horaria aquí
          key_zoom: null,
          customer_id: null
        };   
        localStorage.setItem('patientSelected',event.target.text);     
        localStorage.setItem('usabilityEdit',"true");
        localStorage.setItem('utility',"true");
        localStorage.setItem('pendienteFlujo','false');
        this.openSpecialistRespondtSecondTab(this.dataCrtMeeting);
      break;
    }
  }

  constructor(
    private respondentFormsService: RespondentFormsService,
    private authService: AuthService,
    private usersService: UsersService,
    private meetingService: MeetingService,
    private router: Router,
    public dialog: MatDialog,
    public customersServi:CustomersService,
    public paramsService:ParametersService
    ) {
      //para hacer funcionar modal
      localStorage.setItem('EditMode','false');
      this.loadingTemplate =
      `<button class="btn btn-primary" type="button" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span class="sr-only">Loading...</span>
    </button>`;
    this.noRowsTemplate =
    `<span class="ag-overlay-loading-center">No hay información que mostrar</span>`;
    this.users = [];
    this.meetings = [];
    this.riskPointsRanges = [];
    this.getUsers();
   
    this.dataSesion = this.authService.getDataSesion();

    this.riskSigns = [
      {"answer": "Hombre", "value": 1},
      {"answer": "Hipertensión", "value": 3},
      {"answer": "Colesterol elevado", "value": 2},
      {"answer": "Obesidad", "value": 1},
      {"answer": "Diabetes mellitus", "value": 1},
      {"answer": "Tren", "value": 3},
      {"answer": "Avión", "value": 1},
      {"answer": "Autobús", "value": 3},
      {"answer": "Tranvia", "value": 3},
      {"answer": "Coche", "value": 2},
      {"answer": "Supermercado", "value": 1},
      {"answer": "Entre 37,5 a 38", "value": 1},
      {"answer": "Más de 38", "value": 3},
      {"answer": "Estoy respirando más que otros días (más de 20 veces por minuto)", "value": 1},
      {"answer": "Fiebre", "value": 1},
      {"answer": "Tos seca", "value": 3},
      {"answer": "Sensación de falta de aire ", "value": 2},
      {"answer": "Mocos blanquecinos", "value": 1},
      {"answer": "Pérdida parcial o total de gusto", "value": 2},
      {"answer": "Pérdida total o parcial del olfato", "value": 2},
      {"answer": "Cansancio", "value": 1},
      {"answer": "Dificultad para respirar", "value": 1},
      {"answer": "En contacto con personal sanitario", "value": 2},
      {"answer": "En contacto con Cuerpos y Fuerzas de Seguridad (bomberos, policías, militares etc)", "value": 2},
      {"answer": "Trabajos cara al publico directo (Comercio, hostelería, servicios)", "value": 1},
      {"answer": "He acudido al hospital o similar", "value": 1},
      {"answer": "He acudido recientemente a una zona o región con elevado número de contagios", "value": 1}
    ];

    this.affirmativeRisk = [
      {"question": "¿Vive con familiares que han sido diagnosticados de COVID durante los últimos 15 días?", "value": 2},
      {"question": "¿Ha tenido contacto con personas diagnosticadas de COVID durante los últimos 15 días?", "value": 1}
    ];

    this.affirmativeQuestions = [
      {"question": "¿Cuándo sales de casa que tipo de transporte has utilizado más de una hora sin las medidas de distanciamiento en los últimos 15 días?",
      "answers":[
        {"answer": 'Tren', "value":'3'},
        {"answer": 'Coche', "value":'2'},
        {"answer": 'Autobus', "value":'3'},
        {"answer": 'Tranvia', "value":'3'},
        {"answer": 'Bicicleta', "value":'0'},
        {"answer": 'Ninguna', "value":'0'},
      ]},
      {"question": "¿Cuándo sales de casa que tipo de transporte has utilizado menos de una hora sin las medidas de distanciamiento en los últimos 15 días?",
      "answers":[
        {"answer": 'Tren', "value":'2'},
        {"answer": 'Coche', "value":'1'},
        {"answer": 'Autobus', "value":'2'},
        {"answer": 'Tranvia', "value":'2'},
        {"answer": 'Bicicleta', "value":'0'},
        {"answer": 'Ninguna', "value":'0'},
      ]}
    ];
    this.riskRanges = [
      {"question": "Edad:", "ranks": [
        {"minor": 50, "major": 59, "value": 1},
        {"minor": 60, "major": 69, "value": 2},
        {"minor": 70, "major": 79, "value": 3},
        {"minor": 80, "major": 1000, "value": 4}
      ]}
    ];

    this.builderRiskPointsRanges();
  }

  ngOnInit(): void {
    if(this.dataSesion.logical_medical == 'cardiology' && this.dataSesion.role =='specialist'){
      this.router.navigate(['/list-respondent-form']);
    }
    this.url=window.location.href;
    localStorage.setItem('utility',"false");    
    this.validateSessionApp();
    if(this.dataSesion.logical_medical!='urology'){      
      this.getParams();
    }       
    const datosasa = this.meetings   
    localStorage.setItem('usabilityEdit',"false");
    localStorage.setItem('pendienteFlujo','false');
  }
  getParams(){
    this.paramsService.getParameters().subscribe(res=>{
      //console.log('getParams',res.resp[1].params[0].data)
      this.timeZone = res.resp[1].params[0].data
      this.filterByTimeZone(this.dataSesion.intPhoneCodes)     
    })
  }
  filterByTimeZone(value){
    let dataFilter = this.timeZone.filter(element => element.code==value);
    //console.log('dataFilter',dataFilter[0].timeZone)
    this.zone = dataFilter[0].timeZone
    
  }
  //Abrir modal
  openDialog(): void {
    const dialogRef = this.dialog.open(ModalMeetingsComponent,{
      width:'450px',
      data:'Confirmar cita'
    });
  // Se recibe los datos del modal
    dialogRef.afterClosed().subscribe(
      data =>{
         //console.log("Dialog output:", data)
         this.sendMeeting(data);
         this.Loader();        
      }
  );  
 }
 openSpecialistRespondtFirstTab(data): void {
  //console.log('abre primera tab');
  localStorage.setItem('EditDoctor','firstTab');
  this.router.navigate(['/edit-respondent-form/'+data.dfh_id]);
}

 openSpecialistRespondtSecondTab(data): void {
  //console.log('abre segunda tab',data);
  localStorage.setItem('EditDoctor','true');

  this.router.navigate(['/edit-respondent-form/'+data.dfh_id]);
}


 Loader(): void {
  const dialogRef = this.dialog.open(LoaderComponent,{
    width:'350px',
    data:'Cargando',
    disableClose: true
  });
}
  buildDataFilter(resp,respUsability): void {
    let headersFilter = [];
    let headersMeeting = [];
    let headersReschedule  = [];
    let headersDiagnosed = [];
    let headersUsability = []
    let dataFilter = [];
    let dataFilterOnhold = []
    let dataFilterAnswered = []
    let dataMeeting = [];
    let dataReschedule = [];
    let dataDiagnosed = [];
    let dataUsability = [];
    let riskWeighting = [];
    let featuredHeaders = [];
    let headersFirstTab = [];
    let headersCardiAgendar = [];  
    let headersMeetingCardi = []; 
    let dataFilterCardi = [];
    let dataMeetingCardi = []; 
    let headersCardiDiagnosted = [];
  

    headersFilter.push({
        headerName: 'Agendar cita',
        field: 'agendar_zoom',
        cellRenderer(params): any {
          const arr = params.value.split(',');
          const name = arr[0];
          const respondents_id = arr[1];
          const dfh_id = arr[2];
          const newLink = `<a style="color: #2196f3; text-decoration-line: underline;" id="btn_mdlZoom" respondents_id="${respondents_id}" dfh_id="${dfh_id}" >${name}</a>`;
          return newLink;
        },
        sortable: true,
        filter: true,
        pinned: 'left'
      },
      {
        headerName: 'Prioridad',
        field: 'total_weight_patient',
        sortable: true,
        filter: true,
        pinned: 'left',
        sort: 'desc',
        
      },
      {
        headerName: 'Fecha',
        field: 'created_on_form',
        cellRenderer(params): any {
          const d: Date = new Date();
          d.setTime(params.value);
          const n = d.toLocaleDateString();
          return n
        },
        sortable: true,
        filter: true,
        pinned: 'left',
        sort: 'asc'
      },
        
    );

    
    headersMeeting.push({
        headerName: 'Cita',
        field: 'agendar_zoom',
        width: 150,
        cellRenderer(params): any {
          //  const state=this.status
          const arr = params.value.split(',');
          //console.log('arreglo'+ params.value);
          const name = arr[0];
          // const status = arr[1]
          const respondents_id = arr[1];
          const dfh_id = arr[2];
          const newLink = `<a style="color: #2196f3; text-decoration-line: underline;" id="btn_meeting" respondents_id="${respondents_id}" dfh_id="${dfh_id}" >${name}</a>`;
          return newLink;
        },
        sortable: true,
        filter: true,
        pinned: 'left'
      },    
      {
        headerName: 'Prioridad',
        field: 'total_weight_patient',
        width: 150,
        sortable: true  ,
        filter: true,
        pinned: 'left',
        sort: 'desc'
      },
      {
        headerName: 'Fecha',
        field: 'date_meet',
        width: 150,
        cellRenderer(params): any {
          return params.value;
        },
        sortable: true,
        filter: true,
        pinned: 'left',
        sort: 'asc'
      },
      {
        headerName: 'Hora',
        width: 130,
        field: 'time_meet',
        cellRenderer(params): any {
          return params.value;
        },
        sortable: true,
        filter: true,
        pinned: 'left',
        sort: 'asc'
      },
      {
        headerName: 'Estado',
        field: 'status_meet',
        width: 150,
        cellRenderer(params): any {  
           
          return params.value
        },
        sortable: true,
        filter: true,
        pinned: 'left',
        sort: 'asc'
      },
      {
        headerName: 'Enlace Cita',
        field: 'url_meet',
        width: 150,
        cellRenderer(params): any {    
          const url = params.value
          const newLink = `<a style="color: #2196f3; text-decoration-line: underline;"  href="${url}" target="_blank"> Ingresar</a>`;
          return newLink;
        },
        sortable: true,
        filter: true,
        pinned: 'left',
        sort: 'asc'
      },      
    );
    headersReschedule.push({
      headerName: 'Agendar cita',
      field: 'agendar_zoom',
      cellRenderer(params): any {
        const arr = params.value.split(',');
        const name = arr[0];
        
        const respondents_id = arr[1];
        const mt_id = arr[2];
      
        const newLink = `<a style="color: #2196f3; text-decoration-line: underline;" id="btn_mdlRescheduled" respondents_id="${respondents_id}" dfh_id="${mt_id}" >${name}</a>`;
        return newLink;
      },
      sortable: true,
      filter: true,
      pinned: 'left'
    },
    {
      headerName: 'Prioridad',
      field: 'total_weight_patient',
      sortable: true,
      filter: true,
      pinned: 'left',
      sort: 'desc',
      
    },
    {
      headerName: 'Fecha',
      field: 'created_on_form',
      cellRenderer(params): any {
        const d: Date = new Date();
        d.setTime(params.value);
        const n = d.toLocaleDateString();
        return n
      },
      sortable: true,
      filter: true,
      pinned: 'left',
      sort: 'asc'
    },
      
  );
  headersDiagnosed.push({
    headerName: 'Paciente',
    field: 'agendar_zoom',
    width: 150,
    cellRenderer(params): any {
      //  const state=this.status
      const arr = params.value.split(',');
      //console.log('arreglo'+ params.value);
      const name = arr[0];
      // const status = arr[1]
      const respondents_id = arr[1];
      const dfh_id = arr[2];
      const newLink = `<a style="color: #2196f3; text-decoration-line: underline;" id="btn_diagnosed" respondents_id="${respondents_id}" dfh_id_="${dfh_id}">${name}</a>`;
      return newLink;
    },
    sortable: true,
    filter: true,
    pinned: 'left'
  },
  // {
  //   headerName: 'Teléfono',
  //   field: 'phone',
  //   width: 150,
  //   cellRenderer(params): any {
  //     //console.log('telefono',params.value)
  //     return params.value;
  //   },
  //   sortable: true,
  //   filter: true,
  //   pinned: 'left',
  //   sort: 'asc'
  // },
  {
    headerName: 'Prioridad',
    field: 'total_weight_patient',
    sortable: true,
    filter: true,
    pinned: 'left',
    sort: 'desc',
    
  },
  {
    headerName: 'Fecha',
    field: 'created_on_form',
    cellRenderer(params): any {
      const d: Date = new Date();
      d.setTime(params.value);
      const n = d.toLocaleDateString();
      return n;
      // return params.value;
    },
    sortable: true,
    filter: true,
    pinned: 'left',
    sort: 'asc'
  },
  {
    headerName: 'Formulario',
    field: 'nameForm',
    cellRenderer(params): any {
      //console.log('llega a nombre de formulrio',params)
   const n = params.value
      return n;
    },
    sortable: true,
    filter: true,
    pinned: 'left',
    sort: 'asc'
  },
    
);



headersMeeting.push(
  {
  headerName: 'Cita',
  field: 'agendar_zoom',
  width: 150,
  cellRenderer(params): any {
    //  const state=this.status
    const arr = params.value.split(',');
    //console.log('arreglo'+ params.value);
    const name = arr[0];
    // const status = arr[1]
    const respondents_id = arr[1];
    const dfh_id = arr[2];
    const newLink = `<a style="color: #2196f3; text-decoration-line: underline;" id="btn_meeting" respondents_id="${respondents_id}" dfh_id="${dfh_id}" >${name}</a>`;
    return newLink;
  },
  sortable: true,
  filter: true,
  pinned: 'left'
},    
{
  headerName: 'Prioridad',
  field: 'total_weight_patient',
  width: 150,
  sortable: true  ,
  filter: true,
  pinned: 'left',
  sort: 'desc'
},
{
  headerName: 'Fecha',
  field: 'date_meet',
  width: 150,
  cellRenderer(params): any {
    return params.value;
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
},
{
  headerName: 'Hora',
  width: 130,
  field: 'time_meet',
  cellRenderer(params): any {
    return params.value;
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
},
{
  headerName: 'Estado',
  field: 'status_meet',
  width: 150,
  cellRenderer(params): any {  
     
    return params.value
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
},
{
  headerName: 'Enlace Cita',
  field: 'url_meet',
  width: 150,
  cellRenderer(params): any {    
    const url = params.value
    const newLink = `<a style="color: #2196f3; text-decoration-line: underline;"  href="${url}" target="_blank"> Ingresar</a>`;
    return newLink;
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
},      
);

headersFirstTab.push({
  headerName: 'Paciente',
  field: 'agendar_zoom',
  cellRenderer(params): any {
    const arr = params.value.split(',');
    const name = arr[0];
    const respondents_id = arr[1];
    const dfh_id = arr[2];
    const newLink = `<a style="color: #2196f3; text-decoration-line: underline;" id="btn_specialistRespondentFirstTab" respondents_id="${respondents_id}" dfh_id="${dfh_id}" >${name}</a>`;
    return newLink;
  },
  sortable: true,
  filter: true,
  pinned: 'left'
},
{
  headerName: 'Establecimiento',
  field: 'name_stablishment',
  cellRenderer(params): any {
    //console.log('llega a nombre de formulrio',params)
 const n = params.value
    return n;
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
  
},
{
  headerName: 'Fecha',
  field: 'created_on_form',
  cellRenderer(params): any {
    const d: Date = new Date();
    d.setTime(params.value);
    const n = d.toLocaleDateString();
    return n
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
},
{
  headerName: 'Formulario',
  field: 'nameForm',
  cellRenderer(params): any {
    //console.log('llega a nombre de formulrio',params)
 const n = params.value
    return n;
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
},
{
  headerName: 'Acción',
  field: 'agendar_zoom',
  cellRenderer(params): any {
    const arr = params.value.split(',');
    const name = arr[0];
    const respondents_id = arr[1];
    const dfh_id = arr[2];
    const newLink = ` 
    <div > <button mat-raised-button style="background: #00669A; color: #fff; " class="  m-t-20 m-b-20" type="button"  id="btn_specialistRespondentFirstTab" respondents_id="${respondents_id}" dfh_id="${dfh_id}">Visualizar</button></div>
   `;
    return newLink;
  },
  sortable: true,
  filter: true,
  pinned: 'left'
},
  
);


//cardi hedaers
headersCardiAgendar.push({
  headerName: 'Paciente',
  field: 'agendar_zoom',
  cellRenderer(params): any {
    const arr = params.value.split(',');
    const name = arr[0];
    const respondents_id = arr[1];
    const dfh_id = arr[2];
    const newLink = `<a style="color: #2196f3; text-decoration-line: underline;" id="btn_specialistRespondentSecondTab" respondents_id="${respondents_id}" dfh_id="${dfh_id}" >${name}</a>`;
    return newLink;
  },
  sortable: true,
  filter: true,
  pinned: 'left'
},
{
  headerName: 'Establecimiento',
  field: 'name_stablishment',
  cellRenderer(params): any {
    //console.log('llega a nombre de formulrio',params)
 const n = params.value
    return n;
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
  
},
{
  headerName: 'Fecha',
  field: 'created_on_form',
  cellRenderer(params): any {
    const d: Date = new Date();
    d.setTime(params.value);
    const n = d.toLocaleDateString();
    return n
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
},
{
  headerName: 'Formulario',
  field: 'nameForm',
  cellRenderer(params): any {
    //console.log('llega a nombre de formulrio',params)
 const n = params.value
    return n;
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
},

{
  headerName: 'Acción',
  field: 'agendar_zoom',
  cellRenderer(params): any {
    const arr = params.value.split(',');
    const name = arr[0];
    const respondents_id = arr[1];
    const dfh_id = arr[2];
    const newLink = ` 
    <div > <button mat-raised-button style="background: #00669A; color: #fff; " class="  m-t-20 m-b-20" type="button"   id="btn_specialistRespondentSecondTab" respondents_id="${respondents_id}" dfh_id="${dfh_id}">Visualizar</button></div>
   `;
    return newLink;
  },
  sortable: true,
  filter: true,
  pinned: 'left'
},
  
);

headersCardiDiagnosted.push({
  headerName: 'Paciente',
  field: 'agendar_zoom',
  cellRenderer(params): any {
    const arr = params.value.split(',');
    const name = arr[0];
    const respondents_id = arr[1];
    const dfh_id = arr[2];
    const newLink = `<a style="color: #2196f3; text-decoration-line: underline;" id="btn_meeting" respondents_id="${respondents_id}" dfh_id="${dfh_id}" >${name}</a>`;
    return newLink;
  },
  sortable: true,
  filter: true,
  pinned: 'left'
},
{
  headerName: 'Establecimiento',
  field: 'name_stablishment',
  cellRenderer(params): any {
    //console.log('llega a nombre de formulrio',params)
 const n = params.value
    return n;
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
  
},
{
  headerName: 'Fecha',
  field: 'created_on_form',
  cellRenderer(params): any {
    const d: Date = new Date();
    d.setTime(params.value);
    const n = d.toLocaleDateString();
    return n
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
},
{
  headerName: 'Formulario',
  field: 'nameForm',
  cellRenderer(params): any {
    //console.log('llega a nombre de formulrio',params)
    
 const n = params.value
    return n;
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
},
{
  headerName: 'Acción',
  field: 'agendar_zoom',
  cellRenderer(params): any {
    const arr = params.value.split(',');
    const name = arr[0];
    const respondents_id = arr[1];
    const dfh_id = arr[2];
    const newLink = ` 
    <div > <button mat-raised-button style="background: #00669A; color: #fff; " class="  m-t-20 m-b-20" type="button"  id="btn_meeting" respondents_id="${respondents_id}" dfh_id="${dfh_id}">Visualizar</button></div>
   `;
    return newLink;
  },
  sortable: true,
  filter: true,
  pinned: 'left'
},
  
);


headersUsability.push({
  headerName: 'Paciente',
  field: 'agendar_zoom',
  cellRenderer(params): any {
    const arr = params.value.split(',');
    const name = arr[0];
    const respondents_id = arr[1];
    const dfh_id = arr[2];
    const newLink = `<a style="color: #2196f3; text-decoration-line: underline;" id="btn_utility" respondents_id="${respondents_id}" dfh_id="${dfh_id}" >${name}</a>`;
    return newLink;
  },
  sortable: true,
  filter: true,
  pinned: 'left'
},
{
  headerName: 'Establecimiento',
  field: 'name_stablishment',
  cellRenderer(params): any {
    //console.log('llega a nombre de formulrio',params)
 const n = params.value
    return n;
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
  
},
{
  headerName: 'Fecha',
  field: 'created_on_form',
  cellRenderer(params): any {
    const d: Date = new Date();
    d.setTime(params.value);
    const n = d.toLocaleDateString();
    return n
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
},
{
  headerName: 'Formulario',
  field: 'nameForm',
  cellRenderer(params): any {
    //console.log('llega a nombre de formulrio',params)
    
 const n = params.value
    return n;
  },
  sortable: true,
  filter: true,
  pinned: 'left',
  sort: 'asc'
},
{
  headerName: 'Acción',
  field: 'agendar_zoom',
  cellRenderer(params): any {
    const arr = params.value.split(',');
    const name = arr[0];
    const respondents_id = arr[1];
    const dfh_id = arr[2];
    const newLink = ` 
    <div > <button mat-raised-button style="background: #00669A; color: #fff; " class="  m-t-20 m-b-20" type="button" id="btn_utility" respondents_id="${respondents_id}" dfh_id="${dfh_id}">Visualizar</button></div>
   `;
    return newLink;
  },
  sortable: true,
  filter: true,
  pinned: 'left'
},
  
);



Array.from(resp).forEach((form:any) => { 
  //console.log('*****************',form)
        let patientRank;
        const dataUsr = this.users.find(element => element.usr_id === parseInt(form.respondents_id));
        //console.log('*****************dataUser',dataUsr);
        const riskPointsRangesLength = this.riskPointsRanges.length;
        if (dataUsr !== undefined) {
          // Falta agregar si es face-to-face. agendar_zoom == ''
          let data = {            
            dfh_id: form.dfh_id,
            agendar_zoom: `${dataUsr.name},${form.respondents_id},${form.dfh_id}`,
            created_on_form: form.created_on,
            phone: form.phone,
            nameForm: form.name,
            total_weight_patient: form.total_weight_patient           
          };         
          let pointsPatient = 0;
          form.structure.forEach(segment => {
            segment.pages.forEach(page => {
              page.forEach(question => {
                const nameCol = headersFilter.find(element => element.field === question.name);
                const nameColMee = headersMeeting.find(element => element.field === question.name);
                if (!nameCol) {
                  headersFilter.push({
                    headerName: question.name,
                    field: question.name,
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: {
                      buttons: ['clear', 'apply'],
                    },sort: 'asc'
                  });
                  headersDiagnosed.push({
                    headerName: question.name,
                    field: question.name,
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: {
                      buttons: ['clear', 'apply'],
                    },sort: 'asc'
                  });
                }

                if (!nameColMee) {
                  headersMeeting.push({
                    headerName: question.name,
                    field: question.name,
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: {
                      buttons: ['clear', 'apply'],
                    },
                  });
                }
                data[question.name] = question.answers;
                if (question.answers !== undefined) {
                  if (question.answers.length > 0) {
                    question.answers.forEach(answer => {
                      let points = 0;
                      // Si la respuesta esta en la lista
                      const pointsaffirmativeQuestions = this.affirmativeQuestions.find(element => element.question === question.name);
                      if (pointsaffirmativeQuestions !== undefined ) {
                        switch(pointsaffirmativeQuestions){
                          case '¿Cuándo sales de casa que tipo de transporte has utilizado más de una hora sin las medidas de distanciamiento en los últimos 15 días?':
                            switch(answer){
                              case 'Tren':
                                points = pointsaffirmativeQuestions.value;
                              break;
                              case 'Coche':
                                points = pointsaffirmativeQuestions.value;
                              break;
                              case 'Autobus':
                                points = pointsaffirmativeQuestions.value;
                              break;
                              case 'Tranvia':
                                points = pointsaffirmativeQuestions.value;
                              break;
                              case 'Bicicleta':
                                points = pointsaffirmativeQuestions.value;
                              break;
                              case 'Ninguna':
                                points = pointsaffirmativeQuestions.value;
                              break;
                            }
                            break;
                            case '¿Cuándo sales de casa que tipo de transporte has utilizado menos de una hora sin las medidas de distanciamiento en los últimos 15 días?':
                              switch(answer){
                                case 'Tren':
                                  points = pointsaffirmativeQuestions.value;
                                break;
                                case 'Coche':
                                  points = pointsaffirmativeQuestions.value;
                                break;
                                case 'Autobus':
                                  points = pointsaffirmativeQuestions.value;
                                break;
                                case 'Tranvia':
                                  points = pointsaffirmativeQuestions.value;
                                break;
                                case 'Bicicleta':
                                  points = pointsaffirmativeQuestions.value;
                                break;
                                case 'Ninguna':
                                  points = pointsaffirmativeQuestions.value;
                                break;
                              }
                              break;
                        }                        
                      }
                        // Si la respuesta esta en la lista
                        const pointsRiskSigns = this.riskSigns.find(element => element.answer === answer);
                        if (pointsRiskSigns !== undefined) {
                          points = pointsRiskSigns.value;
                        }
                      // Si la pregunta esta en la lista y la respuesta es afirmativa
                      const pointsAffirmativeRisk = this.affirmativeRisk.find(element => element.question === question.name);
                      if (pointsAffirmativeRisk !== undefined && answer === 'Si') {
                        points = pointsAffirmativeRisk.value;
                      }
                      // Si la pregunta esta en la lista, obtener valor del rango
                      const pointsRiskRanges = this.riskRanges.find(element => element.question === question.name);
                      if (pointsRiskRanges !== undefined) {
                        pointsRiskRanges.ranks.forEach(element => {
                          if (parseInt(answer) >= element.minor && parseInt(answer) <= element.major) {
                            points = element.value;
                          }
                        });
                      }
                      pointsPatient = pointsPatient + points;
                    });
                  }
                }

              });
            });
          });

          for (let i = 0; i < riskPointsRangesLength; i++) {
            const element = this.riskPointsRanges[i];
            if (pointsPatient >= element.minor && pointsPatient <= element.major) {
              patientRank = i;
            }
          }

          this.clinicsData =  JSON.parse(localStorage.getItem('clinics'));
          //console.log('clinicas',this.clinicsData.clinics); 
          if(this.dataSesion.logical_medical== "urology"){
            for(let i=0; i <this.clinicsData.clinics.length;i++){
              const element= this.clinicsData.clinics[i];
              if(element.code== dataUsr.id_stablishment){              
                data['name_stablishment']=element.name;
              }
            }
          }

          data['points'] = patientRank;
          Array.from(respUsability).forEach((formUsability:any) =>  {
            console.log('array data ****',formUsability.dfh_id)
            
            let patientRank;
            if(data.dfh_id +1  == formUsability.dfh_id){
                                        // let dataFormUsablity;
            // dataFormUsablity = formUsability.filter(element=>element.dfh_id  == data.dfh_id)
            const dataUsr = this.users.find(element => element.usr_id === parseInt(formUsability.respondents_id));
            console.log('Data filter', dataUsr);
            const riskPointsRangesLength = this.riskPointsRanges.length;
  
            if (dataUsr !== undefined) {
              // Falta agregar si es face-to-face. agendar_zoom == ''
              let data = {            
                dfh_id: formUsability.dfh_id,
                agendar_zoom: `${dataUsr.name},${formUsability.respondents_id},${formUsability.dfh_id}`,
                created_on_form: formUsability.created_on,
                phone: formUsability.phone,
                nameForm: formUsability.name,
                total_weight_patient: formUsability.total_weight_patient           
              };         
              let pointsPatient = 0;
              formUsability.structure.forEach(segment => {
                console.log("segment*********",segment);              
              });               
            
              this.clinicsData =  JSON.parse(localStorage.getItem('clinics'));
              //console.log('clinicas',this.clinicsData.clinics); 
              if(this.dataSesion.logical_medical== "urology"){
                for(let i=0; i <this.clinicsData.clinics.length;i++){
                  const element= this.clinicsData.clinics[i];
                  if(element.code== dataUsr.id_stablishment){              
                    data['name_stablishment']=element.name;
                  }
                }
              }
              //verificar en que estado se encuentra un formulario antes de el de ipss                  
              switch (formUsability.status) {
                case 'onhold/onhold':
                  dataUsability.push(data);        
                  break;                 
              }
            }
            }

          
          });

          switch (form.status) {
            case 'answered':
                dataFilter.push(data);
              break;
            case 'onhold/onhold':
                dataFilterOnhold.push(data);
                dataFilterAnswered.push(data);
                // dataMeetingCardi.push(data);
            break;
            case 'answered/onhold':
              dataFilterAnswered.push(data);
              // dataMeetingCardi.push(data);
              
          break;
          case 'onhold/answered':
            dataFilterOnhold.push(data);
            // dataFilterAnswered.push(data);
            // dataMeetingCardi.push(data);
            
        break;
        case 'answered/answered':        
          // dataFilterAnswered.push(data);
                        //data de usability 

                        console.log('answered/answered',data.dfh_id);

       
          dataMeetingCardi.push(data);
        break;
            case 'aforementioned':             
                const meeting = this.meetingsScheduled.find(element => parseInt(element.dfh_id) === form.dfh_id &&  element.usr_id==this.dataSesion.id); 
                const meetingExpired = this.meetingsRescheduled.find(element => parseInt(element.dfh_id) === form.dfh_id  &&element.usr_id==this.dataSesion.id );        
                //console.log('meeting_____________',meeting);   
                 if(meeting !== undefined ) {
                  switch(meeting.status){ 
                    case 'onhold': 
                      // const onholdData = this.meetings.reverse();                                            
                      data['date_meet'] = meeting.request.date;
                      data['url_meet'] = meeting.answer_api.join_url; //solo si cumple el estado aparece en citas
                      data['status_meet'] = 'Pendiente';
                      data['phone'] =  meeting.phone;
                      data['time_meet'] = meeting.request.time;  
                      dataMeeting.push(data);
                      break;
                    case 'confirmed':                     
                      data['date_meet'] = meeting.request.date;
                      data['url_meet'] = meeting.answer_api.join_url;                    
                      data['status_meet'] = 'Confirmado';
                      data['phone'] =  meeting.phone;
                      data['time_meet'] = meeting.request.time;  
                      dataMeeting.push(data);//solo si cumple el estado aparece en citas
                      break;                    
                  }                            
                 }  
                 if(meetingExpired !== undefined ) {
                  data['time_meet'] = meetingExpired.request.time;  
                  //console.log('meeting.mt_id',meetingExpired.mt_id)                    
                  dataReschedule.push(data);//si esta expirado no aparece en citas, pero si en reagendar
                }                             
              break;
            case 'expired':
               dataReschedule.push(data);
              break;
            case 'reviewed':
              dataMeetingCardi.push(data);
             break;  
            case 'diagnosed':
              console.log('answered/answered',data.dfh_id);

              if(this.dataSesion.logical_medical=='urology'){
                dataDiagnosed.push(data); 
              }else{
                const meetAttended = this.meetingsAttended.find(element => parseInt(element.dfh_id) === form.dfh_id &&  element.usr_id==this.dataSesion.id);   
                // const meetingExpired = this.meetingsRescheduled.find(element => parseInt(element.dfh_id) === form.dfh_id  &&element.usr_id==this.dataSesion.id );  
                //console.log('ingresoz')      
            
                 if(meetAttended  !== undefined ) {         
                      // const onholdData = this.meetings.reverse();                                            
                      data['date_meet'] = meetAttended.request.date;
                      data['url_meet'] = meetAttended.answer_api.join_url;                   //solo si cumple el estado aparece en citas
                      data['status_meet'] = 'Pendiente';
                      data['phone'] =  meetAttended.phone;
                      data['time_meet'] = meetAttended.request.time;  
                      dataDiagnosed.push(data);                                             
                 }                               
              }
                    
              
              break;              
          }
        }
    });

    this.headersFilter = headersFilter;
    this.headersMeeting = headersMeeting;
    this.headersReschedule = headersFilter;
    this.headersDiagnosed = headersDiagnosed;
    this.dataFilter = dataFilter;
    this.dataFilterOnhold = dataFilterOnhold;
    this.dataFilterAnswered = dataFilterAnswered;
    this.dataMeeting = dataMeeting;
    this.dataReschedule = dataReschedule;
    this.dataDiagnosed = dataDiagnosed;
    this.headerEspecialistFirstTab = headersFirstTab;
    this.headerEspecialistRespondent = headersCardiAgendar;
    this.headerDiagnostedRespondent = headersCardiDiagnosted;
    


    this.headerUsability = headersUsability;
    //cardialogos
    this.dataEspecialistRespondent = dataMeeting;    
    this.dataDiagnosticRespondent = dataMeetingCardi;
    this.dataUsabalityRespondent = dataUsability;
    this.loadGrid = true;
    this.tabGroup.selectedIndex = parseInt(localStorage.getItem('last-page')) ; 
  }

  getUsers(): void { 
    this.usersService.getUsers(localStorage.getItem('customer_id')).subscribe(
      resp => {
        if (resp.correct) {
          let dataUser = resp.resp;
          this.users = resp.resp;
          let dataUserFilter = dataUser.filter(element => element.name==this.dataSesion.name) 
          this.zoomKey=JSON.parse(dataUserFilter[0].key_zoom)
          console.log('datus users',dataUserFilter[0].key_zoom)
          let dataUserFilterbyCustom = dataUser.filter(element => element.id_customer== localStorage.getItem('customer_id')) 
          if(this.dataSesion.id_stablishment == "0"){
            this.users = dataUserFilterbyCustom 
            this.meetingUserId = localStorage.getItem('customer_id');
            this.getRespForms();
            this.getMeetings();
            console.log('GetUsersIf', this.users) 
          }else{
            let fliterByStablishment = dataUserFilterbyCustom.filter(element=> element.id_stablishment==this.dataSesion.id_stablishment)
            this.users = fliterByStablishment 
            this.meetingUserId = localStorage.getItem('customer_id');
            this.getRespForms();
            this.getMeetings();
            console.log('GetUsersElse', this.users) 
          }  
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  getMeetings(): void {
    this.meetingService.getMeetings().subscribe(
      resp => {
        if (resp.correct) {
          let dataMeeting = resp.resp;                       
          this.meetings = dataMeeting.filter(element => element.usr_id==this.dataSesion.id) 
          //console.log('meetingsData', dataMeeting); 
          this.meetingsScheduled = this.meetings.filter(element => element.status=='onhold' || element.status=='confirmed');
          //console.log('onhold', this.meetingsScheduled);           
          this.meetingsRescheduled= this.meetings.filter(element => element.status=='expired');

          this.meetingsAttended = this.meetings.filter(element => element.status == 'attended') 
          //console.log('attended', this.meetingsAttended);                   
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  createMeeting(): void { 
    this.dataCrtMeeting['customer_id'] = localStorage.getItem('customer_id');    
    this.dataCrtMeeting['key_zoom'] = this.zoomKey;
    this.dataCrtMeeting['usr_id'] = this.meetingUserId; 
    this.dataCrtMeeting['UrlOrigin'] = localStorage.getItem('urlOrigin');
    this.dataCrtMeeting['Zone'] = this.zone;
    this.dataCrtMeeting['usr_id'] = this.dataSesion.id;
    //console.log('datos To create', this.dataCrtMeeting);
    
    this.meetingService.createMeeting(this.dataCrtMeeting).subscribe(
      resp => {
        this.Notify = true; 
        if (resp) {
          this.meetings = resp.resp;
          //console.log('se ha enviado la notificación');
          location.reload(); 
          this.router.navigate(['medical-dashboard']); 
        }
      },
      error => {
        //console.log(error + 'error');
        location.reload();
        this.router.navigate(['medical-dashboard']);        
      }
    );
  }
  updateMeeting(): void {
    this.meetingService.createMeeting(this.dataCrtMeeting).subscribe(
      resp => {
        //console.log(resp);
        if (resp.correct) {
          this.meetingsScheduled = resp.resp;
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  builderRiskPointsRanges(): void {
    let ranks = [];
    let totalPoints = 0;
    let min = 0;
    this.riskSigns.forEach(element => {
      totalPoints = totalPoints + element.value;
    });
    this.affirmativeRisk.forEach(element => {
      totalPoints = totalPoints + element.value;
    });
    this.riskRanges.forEach(element => {
      const ranksLength = element.ranks.length;
      const pointsMax = element.ranks[ranksLength - 1].value;
      totalPoints = totalPoints + pointsMax;
    });
    const ratio = totalPoints / 5;
    let max = ratio;
    for (let i = 0; i < 5; i++) {
      const element = {"minor": min, "major": max};
      ranks.push(element);
      min = max + 1;
      max = max + ratio;
    }
    this.riskPointsRanges = ranks;
  }

  getRespForms(): void {
    this.respondentFormsService.getRespForms(localStorage.getItem('customer_id')).subscribe(
      resp => {
        if (resp.correct) {  
          console.log('this.respFormscompleted',resp.resp);               
            this.respForms  = resp.resp.filter(element => element.customer_id == localStorage.getItem('customer_id') && element.answered_check ==false);
            console.log('this.respForms',this.respForms);
            this.respFormsUsablity  = resp.resp.filter(element => element.customer_id == localStorage.getItem('customer_id') && element.answered_check ==true);            
            console.log('respFormsUsablity',this.respFormsUsablity);          
            this.buildDataFilter(this.respForms,this.respFormsUsablity);           
        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  validateSessionApp(): any {
    if (localStorage.getItem('updatesession') === null) {
      localStorage.setItem('updatesession', 'true');
      location.reload();
    }
  }

  showTab(event: MatTabChangeEvent): void {   
   
    const tab = event.tab.textLabel;
    this.tab = event.tab.textLabel;
     //console.log(this.tab);
     switch(tab){
        case 'Angendar cita':
          localStorage.setItem('last-page','0');          
          this.getMeetings();
          this.getRespForms();
          this.appMeetModal =true;   
          break;
        case 'Citas':
          localStorage.setItem('last-page','1');        
          this. getMeetings();
          this.getRespForms();            
          break;
        case 'Reagendar':
          localStorage.setItem('last-page','2');        
          this. getMeetings()
          this.appMeetModal =false;
          this.getRespForms()
          this.meetingService.TabValue('Reagendar');       
          break;
        case 'Pendientes IPSS':
          localStorage.setItem('last-page','0');                      
          break;
        case 'Pendientes flujometría': 
          localStorage.setItem('last-page','1');                      
          break;  
        case 'Comentarios':    
          localStorage.setItem('last-page','3');                      
          break;
     }
  }

  openMdlMeeting(type): void {
    this.mdlMeeting = document.getElementById(type);
    this.mdlMeeting.style.display = 'block';
  }

  openSpecialostRespondForm(){

  }

  closeMdlMeeting(): void {
    this.mdlMeeting = document.getElementById('mdlMeeting');
    this.mdlMeeting.style.display = 'none';
  }

  sendMeeting(data): void {
    if(this.tab==='Pacientes por ver'){
      this.Scheduled(data);    
    }
    if(this.tab==='Reagendar'){
      //console.log('this.dataRescheduled',this.dataRescheduled);
      this.meetingService.rescheduledMeeting(this.dataRescheduled).subscribe(
        resp => {
          if (resp.correct) {
            this.Scheduled(data);
            location.reload();         
            this.router.navigate(['medical-dashboard']);
          }
        },
        error => {
          //console.log(error);
        }
      );      
    }    
  }
  Scheduled(data){
    let dateTime = data.time +' '+data.date;
    //console.log('dateTime',dateTime);
    if( dateTime === this.dateTime[0]){
      //console.log('se cumple');
      this.meetingService.TabValue('error')
    }else{
      this.dataCrtMeeting['date'] = data.date;
      this.dataCrtMeeting['time'] = data.time;
      this.dataCrtMeeting['appointment'] = data.appointment;
      this.createMeeting();
    }
  }



}
