<!-- Normal render -->

<div
  class="main"
  *ngIf="dataCUstomer.render_html=='normal'"
>
  <!-- Título Texto -->

  <div class="tittleDiv">
    <h2 class="textoTittle">
      Termina con la curva de contagios desde los primeros síntomas
    </h2>
  </div>

  <!-- Título Texto -->
  <!-- Boton de Login -->

  <div
    class="btnLoginDiv"
    (click)="openDialog()"
  >
    <h2
      class="text-center"
      style="color: #ffffff;cursor: pointer;"
    >Iniciar sesión</h2>
  </div>

  <!-- Boton de Login -->
  <!-- Botones de registro -->

  <div
    class="btnSingUpDiv"
    *ngIf="btnPatronato == false"
  >
    <!-- <div
      class="btnSingUpBtnGoogle"
      (click)="signInWithGoogle()"
    >
      <h2
        class="text-center"
        style="color: #868686;cursor: pointer;"
      >{{LoginText}} con Google</h2>
    </div> -->

    <div
      class="btnSingUpBtnSMS"
      (click)="openModalOtp()"
    >
      <h2
        class="text-center"
        style="color: #868686;cursor: pointer;"
      >{{LoginText}} con SMS</h2>
    </div>
  </div>

  <!-- Botones de registro -->
  <!-- Texto -->

  <div
    class="textDiv"
    style="margin-top: 15%;"
  >
    <p style="font-size: 14px; color: #00669A; text-align: justify;">
      Cuando rellenas el formulario D&S recibes un pre-diagnósticos sobre el estado de tu salud en relación con el Covid-19. Además un médico especialista en Urgencias te citará para una consulta telemática en la que te aconsejará sobre los siguientes pasos a seguir.
      D&S es un recurso gratuito para ayudarnos a reducir la curva de contagios. Tener la mejor información sobre nuestra salud desde el primer momento nos permite actuar de forma responsable.
    </p>
  </div>

  <div class="LogoEprocesDiv">
    <img
      class="imgEprocess1"
      src="/assets/img/Icon.png"
    >

    <img
      class="imgEprocess2"
      src="/assets/img/LogoD&S.png"
    >
  </div>

  <div
    class="logosAuspDiv"
    *ngIf="ImagesUCA"
  >
    <h2 class="TextAuspi">Con la colaboración de:</h2>

    <img
      class="imgAuspi1"
      src="/assets/img/spri_logo_TRANSPARENTE.png"
    >

    <img
      class="imgAuspi2"
      src="/assets/img/Tecnalia-removebg-preview.png"
    >
  </div>
</div>

<div
  style="z-index: -2;"
  *ngIf="dataCUstomer.render_html=='normal'"
>
  <img src="/assets/img/LoginBackgroung.png">
</div>

<div
  class="main"
  *ngIf="dataCUstomer.render_html=='smartpassport'"
>
  <!-- Boton de Login canal 4 -->

  <div
    class="btnLoginDivCanal4"
    (click)="openDialog()"
  >
    <h2
      class="text-center"
      style="color: #ffffff;cursor: pointer;"
    >Iniciar sesión</h2>
  </div>

  <!-- Boton de Login -->
  <!-- Texto -->
  <!-- Texto -->
  <!-- Texto -->

  <div
    class="textDiv"
    style="margin-top: 30%; position: absolute;"
  >
    <p style="font-size: 14px; color: #00669A; text-align: justify;">
      Herramienta digital que permite conectar los resultados de las pruebas de laboratorio de COVID-19 así como el control y seguimiento de las vacunas aplicadas, con la ciudadanía y las autoridades de forma segura, rápida y funcional.
    </p>
  </div>

  <!-- Texto -->
  <!-- ImagenSmartPassport -->

  <div>
    <img
      class="imageSmartPassportDiv"
      src="/assets/img/smart_passport_Icon.png"
    >
  </div>

  <!-- Logos Eprocess -->
  <div style="margin-top: 31.5%;"></div>

  <div class="LogoEprocesDiv">
    <img
      class="imgEprocess1"
      src="/assets/img/Icon.png"
    >

    <img
      class="imgEprocess4"
      src="/assets/img/spri_logo_TRANSPARENTE.png"
    >
  </div>

  <!-- Logos Eprocess -->
  <!-- Logos auspiciantes -->

  <div
    class="logosAuspDiv"
    *ngIf="ImagesUCA"
  >
    <h2 class="TextAuspi">Con la colaboración de:</h2>

    <img
      class="imgAuspi1"
      src="/assets/img/spri_logo_TRANSPARENTE.png"
    >

    <img
      class="imgAuspi2"
      src="/assets/img/Tecnalia-removebg-preview.png"
    >
  </div>

  <!-- logos Auspiciantes -->
</div>

<div
  style="z-index: -2;"
  *ngIf="dataCUstomer.render_html=='smartpassport'"
>
  <img src="/assets/img/BackGroundSmartPassport.png">
</div>

<div
  class="main"
  *ngIf="dataCUstomer.render_html=='smartforms'"
>
  <!-- Boton de Login canal 4 -->

  <div
    class="btnLoginDivCanal4"
    (click)="openDialog()"
  >
    <h2
      class="text-center"
      style="color: #ffffff;cursor: pointer;"
    >Iniciar sesión</h2>
  </div>

  <!-- Boton de Login -->
  <!-- Texto -->
  <!-- Texto -->
  <!-- Texto -->

  <div
    class="textDiv"
    style="margin-top: 17%; position: absolute;"
  >
    <p style="font-size: 14px; color: #00669A; text-align: justify;">
      Herramienta Digital para realizar diagnósticos telemáticos basados en cuestionarios y algoritmos, utilizada por los médicos para diagnostico temprano y seguimiento de sus pacientes.
    </p>
  </div>

  <!-- Texto -->
  <!-- ImagenSmartPassport -->

  <div>
    <img
      class="imgEprocess2Forms"
      src="/assets/img/smart_forms.png"
    >
  </div>

  <!-- Logos Eprocess -->
  <div style="margin-top: 31.5%;"></div>

  <div class="LogoEprocesDiv">
    <img
      class="imgEprocess1"
      src="/assets/img/Icon.png"
    >

    <img
      class="imgEprocessSForms"
      src="/assets/img/spri_logo_TRANSPARENTE.png"
    >
  </div>

  <!-- Logos Eprocess -->
  <!-- Logos auspiciantes -->

  <div class="logosAuspDiv">
    <h2 class="TextAuspi2">Con la colaboración de:</h2>

    <!-- <img class="imgAuspi1" src="/assets/img/spri_logo_TRANSPARENTE.png">
  <img class="imgAuspi2" src="/assets/img/Tecnalia-removebg-preview.png"> -->

    <img
      class="imgAuspi3"
      src="/assets/img/RodriguezRubioIcon.png"
    >

    <img
      class="imgAuspi4"
      src="/assets/img/PuertaReal.png"
    >

    <img
      class="imgAuspi5"
      src="/assets/img/ClinicaPremium.png"
    >
  </div>

  <!-- logos Auspiciantes -->
</div>

<div
  style="z-index: -2;"
  *ngIf="dataCUstomer.render_html=='smartforms'"
>
  <img src="/assets/img/LoginBackgroung.png">
</div>

<div
  class="main"
  *ngIf="dataCUstomer.render_html=='cardiology'"
>
  <!-- Boton de Login canal 4 -->

  <div
    class="btnLoginDivCanal4"
    (click)="openDialog()"
  >
    <h2
      class="text-center"
      style="color: #ffffff;cursor: pointer;"
    >Iniciar sesión</h2>
  </div>

  <!-- Boton de Login -->
  <!-- Texto -->
  <!-- Texto -->
  <!-- Texto -->

  <div
    class="textDiv"
    style="margin-top: 20.5%; position: absolute;"
  >
    <p style="font-size: 14px; color: #00669A; text-align: justify;">
      Herramienta Digital para realizar diagnósticos telemáticos basados en cuestionarios y algoritmos, utilizada por los médicos para diagnostico temprano y seguimiento de sus pacientes.
    </p>
  </div>

  <!-- Texto -->
  <!-- ImagenSmartPassport -->

  <div>
    <img
      class="imgEprocess2Forms"
      src="/assets/img/smart_forms.png"
    >
  </div>

  <!-- Logos Eprocess -->
  <div style="margin-top: 32.0%;"></div>
  <!-- <div class="LogoEprocesDiv">      
   <img class="imgEprocess1" src="/assets/img/Icon.png" >  
   <img   class="imgEprocessSFormsCardiology"  src="/assets/img/CardiologyIcon.png">       
 </div> -->

  <div class="logosAuspDiv">
    <div style="margin-left: 21%;">
      <img
        class="imgAuspi3"
        src="/assets/img/Icon.png"
      >

      <img
        class="imgAuspi4"
        src="/assets/img/CardiologyIcon.png"
      >
    </div>
  </div>

  <!-- logos Auspiciantes -->
</div>

<div
  style="z-index: -2;"
  *ngIf="dataCUstomer.render_html=='cardiology'"
>
  <img src="/assets/img/LoginBackgroung.png">
</div>

<!-- PRECONSULT RENDER -->

<div class="main"
  *ngIf="dataCUstomer.render_html=='preconsult'">
  <!-- Boton de Login canal 4 -->

  <div
    class="btnLoginDivCanal4"
    (click)="openDialog()"
  >
    <h2
      class="text-center"
      style="color: #ffffff;cursor: pointer;"
    >Iniciar sesión</h2>
  </div>

  <!-- Boton de Login -->
  <!-- Texto -->
  <!-- Texto -->
  <!-- Texto -->

  <div
    class="textDiv"
    style="margin-top: 17%; position: absolute;"
  >
    <p style="font-size: 14px; color: #00669A; text-align: justify;">
      Herramienta Digital para realizar diagnósticos telemáticos basados en cuestionarios y algoritmos, utilizada por los médicos para diagnostico temprano y seguimiento de sus pacientes.
    </p>
  </div>

  <!-- Texto -->
  <!-- ImagenSmartPassport -->

  <div style="margin-top: 9%;">
    <img
      class="imgEprocess2Forms"
      src="/assets/img/Preconsult-color.png"
    >
  </div>

  <!-- Logos Eprocess -->
  <!-- <div style="margin-top: 31.5%;"></div>

  <div class="LogoEprocesDiv">
    <img
      class="imgEprocess1"
      src="/assets/img/Icon.png"
    >

  </div> -->

  <!-- Logos Eprocess -->
  <!-- Logos auspiciantes -->

  <div class="logosAuspDiv" style="margin-top: 22%;">
    <h2 class="TextAuspi2">Con la colaboración de:</h2>

    <!-- <img class="imgAuspi1" src="/assets/img/spri_logo_TRANSPARENTE.png">
  <img class="imgAuspi2" src="/assets/img/Tecnalia-removebg-preview.png"> -->

    <img
      class="imgAuspi3" style="width: 120px;"
      src="/assets/img/Icon.png"
    >

    <img
      class="imgAuspi4" style="width: 130px;"
      src="/assets/img/ITI.png"
    >

    <img
      class="imgAuspi5" style="width: 150px; margin-top: 6%;"
      src="/assets/img/cdti.png"
    >
  </div>

  <!-- logos Auspiciantes -->
</div>

<div
  style="z-index: -2;"
  *ngIf="dataCUstomer.render_html=='preconsult'"
>
  <img src="/assets/img/LoginBackgroung.png">
</div>