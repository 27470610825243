import { Component, OnInit,Inject  } from '@angular/core';
import { RespondentForm } from '../../../models//respondent_form.model';
import { RespondentFormsService } from '../../../services/respondent-forms.service';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-preview-form-respondent',
  templateUrl: './modal-preview-form-respondent.component.html',
  styleUrls: ['./modal-preview-form-respondent.component.css']
})
export class ModalPreviewFormRespondentComponent implements OnInit {
  private id: number;
  public respondentForm: any;
  public structure: any;
  private form: any;
  private currentTab: number;
  private tabs: any;
  public date; 
  public diagnosis: any;
  public pages: any;

  public segments: string[];
  public mdlMessage: any;
  public titleMessage: string;
  public message: string;
  public select = null;
  public warnin =false ;
  public diagnosisText;
  public Presuncion; 
  public Text;
  public respondentFormStructure:any;

  constructor(public dialogRef: MatDialogRef <ModalPreviewFormRespondentComponent>,@Inject(MAT_DIALOG_DATA)public mssg:string,
  private DataForm:RespondentFormsService,private rutaActiva: ActivatedRoute) { }

  ngOnInit(): void {
    const { id } = this.rutaActiva.snapshot.params;
    console.log('modalInfo',this.respondentForm)
    this.id = 887;
    this.GetForms(887);
  }

  GetForms(id){
    this.DataForm.getByIdRespForm(id).subscribe(
      resp => {
        if (resp) {
          this.respondentForm = resp.resp[0];
          this.respondentFormStructure = resp.resp[0].structure
          this.structure =  this.respondentForm.diagnostic_detail;
          console.log('this.structure',resp.resp[0])
          console.log('this.respondentForm',resp)
          // this.contTabs();
          this.diagnosisText=resp.resp[0].diagnosis;
          this.Presuncion=resp.resp[0].presumption;
        }
      },
      error => {
        // //console.log(error);
      }
    );
  }
  answerBuilder(lengthQ, answerNum): any {
    let arrAux: any = [];
    for (let i = 0; i < lengthQ; i++) {
      if (this.form[answerNum + i].checked) {
        const item = this.form[answerNum + i].value;
        arrAux.push(item);
      }
    }
    return arrAux;
  }


  onSubmit(): any {
    if(this.select== null){
      this.warnin=true;    
    }else{
      this.warnin=false;
      let answerNum: number = 0;
      this.form = document.getElementsByClassName('presenter');  
      this.structure.forEach( async (est: any, i) => {
        est.pages.forEach((pg, j) => {
          pg.forEach( async (q, n) => {
            let arrAux: any = [];
            let lengthQ = 1;  
            switch (q.type) {
              case 'Radio':
              case 'Checkbox':
                  lengthQ = q.items.length;
                  arrAux = this.answerBuilder(lengthQ, answerNum);
                break;
              default:
                  // this.diagnosis = this.form[answerNum].value;
                  // arrAux.push(this.diagnosis);
            }
            this.structure[i].pages[j][n]['diagnosis'] = arrAux;
            answerNum = answerNum + lengthQ;
          });
        });
      });

      const diagnosis: any = document.getElementById('diagnosis');
      const presumption: any = document.getElementById('presumption');
      this.respondentForm.custf_id = this.id;
      this.respondentForm.structure = this.structure;
      this.respondentForm['presumption'] = presumption.value;
      this.respondentForm['diagnosis'] = this.diagnosis;
      this.respondentForm['status'] = 'diagnosed';
    }
  }

  openMdlMeeting(): void {
 
    this.mdlMessage = document.getElementById('mdlMessage');
    this.mdlMessage.style.display = 'block';
  }

  closeMdlMessage(): void {
    this.mdlMessage = document.getElementById('mdlMessage');
    this.mdlMessage.style.display = 'none';
   
  }

}
