import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css']
})
export class DateComponent implements OnInit {
  public dateC: any;
  public picker: any;
  public minDate: Date;
  public maxDate: Date;

  @Input() public dtaQuestions: any;
  @Output() AddRmvAnswered = new EventEmitter();

  @HostListener('change', ['$event'])
  onChange(event: any): any {
    const item: any = event.srcElement;
    const itemLength = item.length;
    this.addRmvAnswered(itemLength, event.target.name);
  }

  constructor( ) {
    this.minDate = new Date(2020, 0, 1);
    this.maxDate = new Date();
    this.dateC = null;
  }

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    const attributes = this.dtaQuestions.attributes;
    const required = attributes.find(element => element === 'required');

    if (required) {
      const question_label = document.getElementById(`lbl-${this.dtaQuestions.name}`);
      question_label.setAttribute("required", "true");
      question_label.setAttribute("answered", "false");
    }

    if (this.dtaQuestions.answers !== undefined) {
      this.picker = document.getElementById(`${this.dtaQuestions.name}`);
      this.picker.value = this.dtaQuestions.answers;
    }

  }

  addRmvAnswered(counter: number, lblName: string): void {
    let answered = false;

    if (counter !== 0) {
      answered = true;
    }

    this.AddRmvAnswered.emit({
      question: lblName,
      answered
    });
  }

}
