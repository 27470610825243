import { Component, OnInit, Input  } from '@angular/core';

@Component({
  selector: 'app-checkbox-complement',
  templateUrl: './checkbox-complement.component.html',
  styleUrls: ['./checkbox-complement.component.css']
})
export class CheckboxComplementComponent implements OnInit {

  @Input() public dtaQuestions: any;
  public component: any;

  constructor() { }

  ngOnInit(): void { }

}
