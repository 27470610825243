import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit {
  public counter: any;
  public index: string;
  @Input() public dtaQuestions: any;
  @Input() public sI: any;
  @Input() public pI: any;
  @Input() public iI: any;
  @Output() AddRmvAnswered = new EventEmitter();

  @HostListener('click', ['$event'])
  onClick(event: any): void {
    if (this.counter[event.target.name] !== undefined) {
      if (event.target.checked) {
        this.counter[event.target.name] = this.counter[event.target.name] + 1;
      } else {
        this.counter[event.target.name] = this.counter[event.target.name] - 1;
      }
    } else {
      this.counter[event.target.name] = 1;
    }
    this.addRmvAnswered(this.counter[event.target.name], event.target.name);
  }

  constructor() {
    this.counter = [];
  }

  ngOnInit(): void { }

  ngAfterViewChecked(): void {
    const attributes = this.dtaQuestions.attributes;
    const required = attributes.find(element => element === 'required');

    this.index =  `${this.sI}.${this.pI}.${this.iI}`;

    if (required) {
      const question_label = document.getElementById(`lbl-${this.dtaQuestions.name}`);
      question_label.setAttribute("required", "true");
    }

    // //console.log(this.dtaQuestions.name);

    if (this.dtaQuestions.answers !== undefined) {
      const lengthQuestions = this.dtaQuestions.answers.length;

      for (let i = 0; i < lengthQuestions; i++) {
        const answer = this.dtaQuestions.answers[i];
        let element: any = document.getElementById(`${this.dtaQuestions.name}-${answer}`);
        element.checked = true;
      }

    }
  }

  addRmvAnswered(counter: number, lblName: string): void {
    let answered = false;

    if (counter !== 0) {
      answered = true;
    }

    this.AddRmvAnswered.emit({
      question: lblName,
      answered
    });
  }


}
