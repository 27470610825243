import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-setting-form',
  templateUrl: './edit-setting-form.component.html',
  styleUrls: ['./edit-setting-form.component.css']
})
export class EditSettingFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
