
<button type="button" class="close" aria-label="Close" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<p class="text-center" style="color: #5288ec; font-size: 14px;">Inicie sesión para continuar</p>
<div class="text-center">
  <img alt="homepage" src="assets/img/Icon.png"> 
</div>

<div *ngIf="msg==' '" class="bg-danger p-10 text-white">{{ msg }}</div>
<div class="form__group field">
  <label for="usr" class="form__label">Usuario</label>
  <input type="text" [(ngModel)]="loginForm.userName" class="form__field" placeholder="Usuario" name="usr" id='usr'  required />  
</div>

<div  class="form__group field">
    <label for="password" class="form__label">Contraseña</label>
    <input  type="password" [(ngModel)]="loginForm.pass" class="form__field" placeholder="Contraseña" name="password" id='password' required  />  
</div>

<div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <button mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="login()">Iniciar sesión</button>
</div>


