import { Component, OnInit } from '@angular/core';
import { PingMiddlewareService } from '../../services/ping-middleware.service';

@Component({
  selector: 'app-ping-middleware',
  templateUrl: './ping-middleware.component.html',
  styleUrls: ['./ping-middleware.component.css']
})
export class PingMiddlewareComponent implements OnInit {

  public answerPing: any;

  constructor( private pingMiddl: PingMiddlewareService) {  }

  ngOnInit(): void {
    this.pingMidd();
  }

  pingMidd(): void {
    this.pingMiddl.pingMidd().subscribe(
      response => {
        if (response.correct) {
          const strAnswer = {
            correct: true,
            resp: response
          };
          this.answerPing = JSON.stringify(strAnswer);
        }
      },
      error => {
        const strAnswer = {
          correct: true,
          resp: error
        };
        this.answerPing = JSON.stringify(strAnswer);
      }
    );
  }

}
