<div class="main">

  <!-- <div class="curtain"></div> -->

  <!-- <app-modal-meet-conf *ngIf="!alert" (ConfirmMeeting)="confirmMeeting()"></app-modal-meet-conf> -->

    <div *ngIf="alert" class="alert alert-warning alert-dismissible fade show" role="alert">
     {{message}}
    </div>
  <div class="container">
    <section>
      <div class="middle">
        <div id="login">
        </div>       
      </div>
    </section>
      <div class="undisplay" id="messageContainer" style="margin-top: 50px;" >
        <div class="row justify-content-md-center">
          <div class="col-md-auto">
            <div class="alert">
              <span id="loginMessage"></span>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
