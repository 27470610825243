<!-- <div style="width: 500px; margin-left: 23%;margin-right: auto;left: 0;right: 0;text-align: center;">
    <zxing-scanner  #scanner (scanSuccess)="scanSuccessHandler($event)" ></zxing-scanner> 
</div>
<div *ngIf="iconShow != ''" style="margin-left: -13%; margin-right: auto;left: 0;right: 0;text-align: center;">
   
    <h3 >Diagnóstico: Negativo </h3>
    <h3>{{message}}</h3>
    <img style="width: 50px;" [src]="iconShow" >
    
</div> -->


<div *ngIf="iconShow == ''" class="logoSmartPassportMobile" >
    <img style="width: 54%;" src="/assets/img/LogoSmartPassport.png" >    
</div>

<mat-card *ngIf="iconShow != ''" (click)="reload()" style="border-radius: 10mm;">
    <h3 style="color: #053890;"> <u> Volver a consultar</u></h3>
</mat-card>

<div *ngIf="!camScan" style="position: absolute;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
text-align: center; z-index: 999; margin-top: 30%;">
    <img style="width: 30%;" src="/assets/img/qr-code.png" >  
    <h2 style="color: rgb(255, 255, 255);">Código escaneado</h2>  
</div>
<div  *ngIf="camScan" class="Camera">
    <zxing-scanner  #scanner (scanSuccess)="scanSuccessHandler($event)" ></zxing-scanner> 
</div>

<div style="background: #053890; width: 100%; height: 100%; position: fixed; z-index: 10; margin-left: -5;"></div>
    <!-- *ngIf="iconShow != ''" -->
    <mat-card *ngIf="iconShow != ''" class="TextDiv" >
        <div *ngIf="!QRExpired">
            <h3 style="color: #053890;">Cédula: {{cedula}}</h3>
            <h3 style="color: #053890;">Fecha: {{created_on}}</h3>
            <!-- <h3 style="color: #053890;">Diagnóstico: Negativo </h3> -->
            <h3 style="color: #053890;">{{message}}</h3>
            <img style="width: 50px;" [src]="iconShow">
        </div>
        <div *ngIf="QRExpired">
            <h3 style="color: #053890;">Código QR Expirado</h3>          
            <img style="width: 50px;" src="/assets/img/clock.png">
        </div>     
    </mat-card>
    

    <!-- <mat-card  style="position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center; bottom: -15%; width: 80%; z-index: 9999999999999999;" (click)=" signOut()">
        <h3 style="color: #053890;"> <u (click)=" signOut()">Cerrar Sesión</u> </h3>    
    </mat-card> -->

  
    
