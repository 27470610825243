import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import { dataConfig } from 'src/app/config/data_config'; 
import { globalData } from 'src/app/global/global_data';

@Component({
  selector: 'app-modal-pdf-view',
  templateUrl: './modal-pdf-view.component.html',
  styleUrls: ['./modal-pdf-view.component.css']
})
export class ModalPdfViewComponent implements OnInit {
  urlPDF; 
  constructor(public dialogRef: MatDialogRef <ModalPdfViewComponent>,@Inject(MAT_DIALOG_DATA)public message,) {
   
   }

  ngOnInit(): void {
    this.urlPDF= globalData.urls.fileURl+'/pdf/forms/'+ this.message; //173.249.10.18.3009
    console.log('Modal abierto',this.message)
  }

  closeModal(){
    this.dialogRef.close();   
   }  

}
