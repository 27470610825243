<div class="">

  <label id="lbl-{{dtaQuestions.name}}" class="lbl lbl-question" >{{dtaQuestions.name}}</label>

    <label class="container" *ngFor="let item of dtaQuestions.items;">
      <label class="form-check-label check_label" for="{{dtaQuestions.name}}">{{item}}</label>
      <input type="checkbox" class="presenter" name="{{dtaQuestions.name}}" id="{{dtaQuestions.name}}-{{item}}" value="{{item}}" >
      <span class="checkmark"></span>
    </label>


</div>



