<mat-toolbar *ngIf="dataSesion.logical_medical=='urology' && dataSesion.role== 'doctor'||dataSesion.logical_medical=='urology' && dataSesion.role== 'specialist'" style="background: #00669A;">
  <div class="pointer" style="color: #00669A; background: rgb(255, 255, 255); border-radius: 50mm; width: 3%;" [routerLink]="['/']">
      <span style="margin-left: 20%; margin-top: 20%;" routerLinkActive="router-link-active" class="material-icons">
    keyboard_backspace
    </span>
  </div>
  <div class="pointer" style="background: #00669A;" [routerLink]="['/']" style="margin-left: 1%; color: rgb(255, 255, 255);">
      Regresar al tablero médico.
  </div>


</mat-toolbar>

<mat-toolbar *ngIf="dataSesion.logical_medical=='cardiology' && dataSesion.role== 'doctor'||dataSesion.logical_medical=='cardiology' && dataSesion.role== 'specialist'" style="background: #00669A;">
  <div class="pointer" style="color: #00669A; background: rgb(255, 255, 255); border-radius: 50mm; width: 3%;" [routerLink]="['/']">
      <span style="margin-left: 20%; margin-top: 20%;" routerLinkActive="router-link-active" class="material-icons">
    keyboard_backspace
    </span>
  </div>
  <div class="pointer" style="background: #00669A;" [routerLink]="['/list-respondent-form']" style="margin-left: 1%; color: rgb(255, 255, 255);">
      Regresar 
  </div>

</mat-toolbar>
<!-- <div style="font-size: 25px; color: #00669A; margin-top: 5%; margin-bottom: 5%;">
 Paciente: <b>{{actualPatient}}</b>
</div> -->
<p class="titel_form">{{respondentForm.name}}</p>
<app-modal-farewell (CloseModalFrw)="closeModalFrw()"></app-modal-farewell>
<form *ngIf="disbaleTableView" class="respondent_form" id="editRespondentForm" #editRespondentForm="ngForm" (ngSubmit)="onSubmit()">

  <section *ngFor="let segment of structure; let sI = index;">
    
   
    <div *ngFor="let page of segment.pages; let pI = index;" >

      <div *ngIf="sI == 0 && pI == 0; then firstDisplay else followingDisplay"></div>

      <ng-template #firstDisplay>
        <div class="tab tab0">
          <p class="desc_form">{{respondentForm.description}}</p>
          <h3 class="titel_seg" *ngIf="pI == 0;">{{segment.name}}</h3>
          <img src="{{segment.image}}">

            <ul>
              <li class="li_question" *ngFor="let question of page; let iI = index;">

                <ul [ngSwitch]="question.class">
                  <li *ngSwitchCase="'Calc'">                   
                      <div style=" position: absolute; margin-top: -1%;   cursor: pointer; width:5%; height: 2%; border-radius: 5mm; background:#00669A;" (click)="formBUilder(question.formula)">                      
                        <p style="color: rgb(247, 245, 245); margin-left: 10%; margin-top: 10%; font-size: 10px;">Calcular</p>
                      </div>                 
                    <br>                   
                  </li>
                </ul>

                <ul [ngSwitch]="question.type">
                  <li *ngSwitchCase="'Text'">
                      <app-text-number [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)"></app-text-number>
                  </li>
                  <li *ngSwitchCase="'Number'">
                      <app-text-number [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)"></app-text-number>
                  </li>
                  <li *ngSwitchCase="'Textarea'">
                    <app-textarea [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)"></app-textarea>
                  </li>
                  <div *ngIf="question.name != 'Obstruido:' && question.name != 'Tipo Perfil Flujometría:'">
                    <li *ngSwitchCase="'Radio'">
                      <app-radio [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)"></app-radio>
                    </li>
                  </div>

                  <li *ngSwitchCase="'Checkbox'">
                    <app-checkbox [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)"></app-checkbox>
                  </li>
                  <li *ngSwitchCase="'Combobox'">
                    <app-combobox [dtaQuestions]="question"></app-combobox>
                  </li>
                  <li *ngSwitchCase="'Email'">
                    <app-email [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)"></app-email>
                  </li>
                  <li *ngSwitchCase="'File'">
                    <app-file [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)" (FileMessage)="fileMessage($event)"></app-file>
                  </li>
                  <li *ngSwitchCase="'Tel'">
                    <app-tel [dtaQuestions]="question"></app-tel>
                  </li>
                  <li *ngSwitchCase="'Datepicker'">                    
                    <label  for="start">{{question.name}}
                    </label>
                    <div>                      
                      <input name={{question.name}} type="text" placeholder="{{question.answers}} " placeholder="dd/mm/aaaa"
                      onfocus="(this.type='date')"
                      onblur="(this.type='date')">
                    </div>                    
                  </li>
                  <li *ngSwitchCase="'Image'">
                    <img src="{{question.name}}">
                  </li>
                  <li *ngSwitchCase="'Radio-complement'">
                    <app-radio-complement [dtaQuestions]="question"></app-radio-complement>
                  </li>
                  <li *ngSwitchCase="'Checkbox-complement'">
                    <app-checkbox-complement [dtaQuestions]="question"></app-checkbox-complement>
                  </li>
                  <!-- <li *ngSwitchCase="'Calc'"></li> -->
                </ul>
                <ul [ngSwitch]="question.style">
                  <li *ngSwitchCase="'error'">                      
                        <p style="color: rgb(255, 0, 0); margin-top: -1%; margin-left: 1%; font-size: 12px; position: absolute;">*Debe completar este campo para realizar el cálculo</p>                                  
                    <br>                   
                  </li>
                </ul>



              </li>

            </ul>

          </div>

      </ng-template>

      <ng-template #followingDisplay>
        <div class="tab">
          <h3 class="titel_seg" *ngIf="pI == 0;">{{segment.name}}</h3>

          <ul>
            <li class="li_question" *ngFor="let question of page; let iI = index;">
              <ul [ngSwitch]="question.class">
                <li *ngSwitchCase="'Calc'">                   
                    <div style=" position: absolute; margin-top: -1%;   cursor: pointer; margin: auto; width: 10%; border-radius: 5mm; background:#00669A;" (click)="formBUilder(question.formula, $event)">                      
                      <p style="color: rgb(247, 245, 245); margin-left: 10%; font-size: 10px;">Calcular</p>
                    </div>                 
                  <br>
                 
                </li>
              </ul>
              <ul [ngSwitch]="question.type">
                <li *ngSwitchCase="'Text'">
                    <app-text-number [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)"></app-text-number>
                </li>
                <li *ngSwitchCase="'Number'">
                    <app-text-number [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)"></app-text-number>
                </li>
                <li *ngSwitchCase="'Textarea'">
                  <app-textarea  [(ngModel)]="comentarios" [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)"></app-textarea>
                </li>
                <div *ngIf="question.name != 'Obstruido:'">
                  <li *ngSwitchCase="'Radio'">
                    <app-radio [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)"></app-radio>
                  </li>
                </div>

                <li *ngSwitchCase="'Checkbox'">
                  <app-checkbox [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)"></app-checkbox>
                </li>
                <li *ngSwitchCase="'Combobox'">
                  <app-combobox [dtaQuestions]="question"></app-combobox>
                </li>
                <li *ngSwitchCase="'Email'">
                  <app-email [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)"></app-email>
                </li>
                <li *ngSwitchCase="'File'">
                  <app-file [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)" (FileMessage)="fileMessage($event)"></app-file>
                </li>
                <li *ngSwitchCase="'Tel'">
                  <app-tel [dtaQuestions]="question"></app-tel>
                </li>
                <li *ngSwitchCase="'Datepicker'">
                  <!-- <app-date [dtaQuestions]="question" [sI]="sI" [pI]="pI" [iI]="iI" (AddRmvAnswered)="addRmvAnswered($event)"></app-date> -->
                  <label  for="start">{{question.name}}

                  </label>
                  <div >                      
                    <input name={{question.name}} type="text" placeholder="{{question.answers}} " placeholder="dd/mm/aaaa"
                    onfocus="(this.type='date')"
                    onblur="(this.type='date')">
                   </div>
                  <!-- <div *ngIf="question.answers != undefined">                      
                    <input name={{question.name}} type="text" placeholder="{{question.answers}}"
                    onfocus="(this.type='date')"
                    onblur="(this.type='date')">
                   </div>

                   <div  *ngIf="question.answers == undefined">
                     
                   <input   name={{question.name}} *ngIf="question.answers == undefined || question.answers[0] == ''" type="text" placeholder="dd/mm/aaaa"
                   onfocus="(this.type='date')"
                   onblur="(this.type='text')">
                   </div> -->

                   
                </li>
                <li *ngSwitchCase="'Image'">
                  <img src="{{question.name}}">
                </li>
                <li *ngSwitchCase="'Radio-complement'">
                  <app-radio-complement [dtaQuestions]="question"></app-radio-complement>
                </li>
                <li *ngSwitchCase="'Checkbox-complement'">
                  <app-checkbox-complement [dtaQuestions]="question"></app-checkbox-complement>
                </li>
                <!-- <li *ngSwitchCase="'Calc'"></li> -->
              </ul>
              <ul [ngSwitch]="question.style">
                <li *ngSwitchCase="'error'">                    
                      <p style="color: rgb(255, 0, 0); margin-top: -1%; margin-left: 1%; font-size: 12px; position: absolute;">*Debe completar este campo para realizar el cálculo</p>                                
                  <br>
                 
                </li>
              </ul>


            </li>

          </ul>

        </div>
      </ng-template>

    </div>

  </section>

  <div class="undisplay" id="messageContainer" style="margin-top: 50px;" >
    <div class="row justify-content-md-center">
      <div class="col-md-auto">
        <div class="alert">
          <span class="closebtn" (click)="unDisplay()" >&times;</span>
          <span id="loginMessage"></span>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="card col-sm-12" *ngIf=" pendienteFlujo == 'true'"  >
    <div>       
        <label for="diagnosis" style="padding-top: 20px;">Comentarios</label>      
        <textarea [(ngModel)]="comentarios" name="comentarios" matInput  rows="8"  ></textarea>
    </div>
  </div> -->


  <div style="overflow:auto;">
    <div style="float:right;">
      <button class="btn btn-secondary" type="button" id="prevBtn" (click)="nextPrev(-1)">Anterior</button>
      <button class="btn btn-success" type="button" id="nextBtn" (click)="nextPrev(1)">Siguiente</button>
    </div>
  </div>

  <button type="button" class="btn-uptop" style="right: 150x;" (click)="upTop()"><i class="fa fa-chevron-up" aria-hidden="true"></i></button>

  <div style="text-align:center;margin-top:40px;">
    <span class="step" *ngFor="let pag of pages;"></span>
  </div>

</form>




