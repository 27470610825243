import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, HostListener  } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { SocialAuthService } from "angularx-social-login";
// import { GoogleLoginProvider } from "angularx-social-login";
import { RespondentFormsService } from '../../services/respondent-forms.service';
import { AuthService } from '../../services/auth.service';
import { ParametersService } from '../../services/parameters.service';
import { OtpService } from '../../services/otp.service';
import { Users } from '../../models/users.model';
import { UsersService } from '../../services/users.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalLoginComponent } from '../_presenters/modal-login/modal-login.component';
import { ModalComponent } from '../_presenters/modal-otp/modal.otp.component';
import { ModalAlertComponent } from '../_presenters/modal-alert/modal-alert.component';
import { ModalOtpCheckComponent } from '../_presenters/modal-otp-check/modal-otp-check.component';
import { CustomersService } from '../../services/customers.service';
import { ModalPrivacyPoliciesComponent } from '../_presenters/modal-privacy-policies/modal-privacy-policies.component';
import { CustomersFormsService } from '../../services/customers-forms.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public intPhoneCodes: any;
  public authForm: Users;
  public modalPrvPlc: any;
  public modalOtp: any;
  private usrAuth: any;
  public url;
  public checkUrl;
  public channelCustomer;
  public ErrorMsg;
  public idCustomerDomain;
  public structureByCustomer;
  public LoginText = 'Registrarse';
  public ImagesUCA: Boolean
  public btnPatronato= false;
  public idCustomer;
  public dataCUstomer;
  
  
  @HostListener('change', ['$event'])
  onChange(event: any): any {
    if (event.srcElement.id === 'password') {
      this.loginByDefault();
    }
  }

  constructor(
    private respondentFormsService: RespondentFormsService,
    // private socialAuthService: SocialAuthService,
    private authService: AuthService,
    private parametersService: ParametersService,
    private otpService: OtpService,
    private usersService: UsersService,
    private router: Router, private customer: CustomersService,
    
    private customerForms:CustomersFormsService,
    private titleService:Title,
    public dialog: MatDialog) {
    
    this.authForm = {
      usr_id: null,
      doc_id: null,
      google_id: null,
      phone: null,
      code: 0,
      name: null,
      password: null,
      email: null,
      role: 'respondent',
      provider: 'default',
      status: null,
      created_on: null,
      int_phone_codes: null,
      id_customer: '1'            
       
    };
    this.getIntPhoneCodes();
   }

  ngOnInit(): void { 
    this.url = window.location.href;
    this.getByDomain();
    this.getUrl()
    console.log('url de login',this.url);
    localStorage.setItem('urlToMail',this.url);
    // this.usrAuth.idCustomer
    console.log('Version 1.1.11')
 
   }

   getByDomain(){
    var encodedData = window.btoa(this.url);     
    // ////console.log('url codificada', encodedData)   
    this.customer.getByDomain(encodedData).subscribe(res => {
        console.log('Datos de canal',res.resp[0])
        this.dataCUstomer=res.resp[0];
        this.idCustomerDomain = res.resp[0].id_customer;
        // Borrar esta parte de código despues de que se establezca bien el requeremiento
        if(res.resp[0].id_customer==2){
          this.LoginText = 'Ingresar';
          this.ImagesUCA = false;         
        }if(res.resp[0].id_customer==4){
          this.btnPatronato = true;
          this.idCustomer = res.resp[0].id_customer;
          this.titleService.setTitle("SmartPassport");
        }
        if(res.resp[0].id_customer==1){
          this.LoginText = 'Registrarse';
          this.ImagesUCA = true;
        }
        if(res.resp[0].logical_medical=='urology'){
          let clinics = JSON.stringify(res.resp[0].medical_center)         
          localStorage.setItem('clinics',clinics);
          localStorage.setItem('logical_medical','urology')
        } 
        if(res.resp[0].logical_medical=='cardiology'){
          localStorage.setItem('logical_medical','cardiology');
          localStorage.setItem('hash_stablishment',res.resp[0].hash);

        }  
        if(res.resp[0].logical_medical=='preconsult'){          
          let clinics = JSON.stringify(res.resp[0].medical_center)         
          localStorage.setItem('clinics',clinics);
          localStorage.setItem('logical_medical','preconsult');
        

        }          
        
        // this.customer.idSession(this.idCustomerDomain);
        this.authForm['id_customer'] = res.resp[0].id_customer;
        this.getCustomersFormsById();
    }, err => {
      console.log(JSON.stringify(err)+ 'error');
    });
   }
   getCustomersFormsById(){
    var encodedData = window.btoa(this.url);     
    // ////console.log('url codificada', encodedData)   
    this.customerForms.getByIdCustForm(this.idCustomerDomain).subscribe(res => {
        ////console.log('Id customer obtenido por getByIdCustForm',res.resp[0].structure)
        this.structureByCustomer = res.resp[0].structure;       
    }, err => {
      ////console.log(JSON.stringify(err)+ 'error');
    });
   }

   openDialog(): void {
    const dialogRef = this.dialog.open(ModalLoginComponent,{
      width:'350px',
      data:'Confirmar cita',
      // disableClose: true
    });    
    dialogRef.afterClosed().subscribe(res=>{       
      if(res){    
        ////console.log(res.userName);  
        //Verificar logica para encryptar nombre para el login o no
        this.authForm.password = res.pass; 
        if(this.dataCUstomer.logical_medical == 'cardiology'){
          let userDataDcrypt = {
            usr_data:res.userName,
            hash:this.dataCUstomer.hash
            
          }
          console.log('antes del ncrypt');

          this.authService.ncryptUserData(userDataDcrypt).subscribe(res=>{
            console.log('en el ncrypt',res);
            this.authForm.name = res.data.doc_id;           
             this.auth();
          });
           
        }else{
          this.authForm.name = res.userName;
        this.authForm.password = res.pass; 
        this.auth(); 
        }
        
      }
    });    
   }


   openModalOtp(): void {
    const dialogRef = this.dialog.open(ModalComponent,{
      width:'450px',
      data:'Confirmar cita',
      // disableClose: true
    });    
    dialogRef.afterClosed().subscribe(res=>{  
      if(res){ 
        this.requestCodeSMS(res); 
        this.openModalOtpCheck()       
       
      }       
      
    })    
   }
   openModalOtpCheck(): void {
    const dialogRef = this.dialog.open(ModalOtpCheckComponent ,{
      width:'450px',
      data:'Confirmar cita',
      // disableClose: true
    });    
    dialogRef.afterClosed().subscribe(res=>{      
      if(res){
         this.signInOTP(res);
      }      
    })    
   }

   openAlertModal(): void {
    const dialogRef = this.dialog.open(ModalAlertComponent,{
      width:'350px',
      data: this.ErrorMsg,
      // disableClose: true
    });    
    dialogRef.afterClosed().subscribe(res=>{     
      this.openDialog()            
    })    
   }

   openPrivacyPoliciesModal(): void {
    const dialogRef = this.dialog.open(ModalPrivacyPoliciesComponent,{
      width:'600px',
      data: this.ErrorMsg,
      // disableClose: true
    });    
    dialogRef.afterClosed().subscribe(res=>{        
      this.privacyPolicies(res)                
    })    
   }

  public loginByDefault(): void {
    const btn_auth: any = document.getElementById('btn_auth');
    btn_auth.focus();
  }

  getIntPhoneCodes(): any {
    this.parametersService.getParameters().subscribe(
      resp => {
        const answer = resp.resp;
        const intPhoneCodes = answer.find((params) => {
          return params.name === 'int_phone_codes';
        });
        this.intPhoneCodes = {
          name: 'Código Int.',
          id_input: 'id_input',
          params: [intPhoneCodes.params[0].data]
        };
      },
      err => {
        // ////console.log(err+ 'es este');
      }
    );
  }

  getByIdUsrRespForm(id): any {
    this.respondentFormsService.getByIdUsrRespForm(id).subscribe(
      resp => {
        let routeInit: string;
        if (resp) {
          if (resp.correct && resp.resp.length > 0) {
            if (resp.resp[0].answered_check) {
              routeInit = `list-respondent-form`;
            } else {
              // routeInit = `edit-respondent-form/${id}`;
               routeInit = `list-respondent-form`;
            }
          } else {
            routeInit = `list-respondent-form`;
          }
          this.router.navigate([routeInit]);
        }
      },
      error => {
        ////console.log(error+ 'es este');
      }
    );
  }

  updateStatusUsr(): any {
    const data = {
      type: 'status',
      id: this.usrAuth.id,
      dataset: {
        status: this.usrAuth.status
      },
      session: true
    };

    this.usersService.updateStatusUsr(data).subscribe(
      resp => {
        ////console.log('Se mando',resp);
      },
      err => {
        ////console.log('No se mando',err);
      }
    );
  }

  createSession(): void {
    localStorage.setItem('id', this.usrAuth.id);
    localStorage.setItem('name', this.usrAuth.name);
    localStorage.setItem('role', this.usrAuth.role);
    localStorage.setItem('email', this.usrAuth.email);
    localStorage.setItem('token', this.usrAuth.token);
    localStorage.setItem('intPhoneCodes', this.usrAuth.intPhoneCodes);
    localStorage.setItem('customer_id',this.idCustomerDomain);
    localStorage.setItem('id_stablishment',this.usrAuth.id_stablishment);

    // this.getUrl()
   
  }

  auth(): void {
    this.authForm['url'] = this.url;
    this.authForm['structure'] =   this.structureByCustomer 
    this.authForm['id_customer'] = this.idCustomerDomain  
    ////console.log('this.authForm',this.authForm)
    //descomentar 
    this.authService.auth(this.authForm).subscribe(
      resp => {
        this.usrAuth = resp;
        //console.log('this respond', this.usrAuth)
        // ////console.log('input value auth', this.usrAuth.idCustomer)        
        if (resp.msg == 'Authorized' &&  this.usrAuth.idCustomer ==  this.idCustomerDomain ) {//verficia si esta autorizado y si tiene un customer id
          let routeInit: string;
          switch (resp.role) {
            case 'admin':
                routeInit = 'list-forms';
              break;
              case 'guard':
                routeInit = 'guard-page';
              break;
            case 'doctor':           
                routeInit = 'medical-dashboard';                             
              break;
            case 'customer':  
                routeInit = 'list-respondent-form';
              break;
            case 'specialist':
              //console.log('specialist case', this.idCustomerDomain);
              if(this.dataCUstomer.logical_medical=='urology' ){
                routeInit = 'medical-dashboard';
              }else{
                routeInit = 'list-respondent-form';
              }
                
            break;
            case 'respondent':
                if (resp.status === 'onhold') {
                  // this.openModalPP();
                  ////console.log('Esta en onhold')
                  this.openPrivacyPoliciesModal()
                } else {
                  this.getByIdUsrRespForm(resp.id);
                }
              break;
          }
          this.authService.registerFCMToken(resp);
          this.createSession();
          this.router.navigate([routeInit]);
        }
        else{        
          
          this.ErrorMsg='Usuario o contraseña no valido(a)'
          this.openAlertModal()          
          const messageContainer = document.getElementById('messageContainer');
          const loginMessage = document.getElementById('loginMessage');
          messageContainer.classList.remove("undisplay");
          loginMessage.innerHTML = 'Usuario o contraseña no valido(a)';
        }
      },
      err => {
        // ////console.log(err);
        const messageContainer = document.getElementById('messageContainer');
        const loginMessage = document.getElementById('loginMessage');
        messageContainer.classList.remove("undisplay");
        loginMessage.innerHTML = err;
      }
    );
  }

  unDisplay(): void {
    const messageContainer = document.getElementById('messageContainer');
    messageContainer.classList.add('undisplay');
  }

  // signInWithGoogle(): void {
  //   this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
  //     data => {
  //       this.authForm.google_id = data.id;
  //       this.authForm.name = data.name;
  //       this.authForm.email = data.email;
  //       this.authForm.provider = data.provider.toLowerCase();
  //       this.auth();
  //     }
  //   );
  // }

  requestCodeSMS(data): void {  
  
    const dtaSend = {
      name: `${data.name} ${data.lastname}`,
      intPhoneCodes: `${data.id_input}`,
      phone: `${data.phone}`,
      id_customer: `${this.idCustomerDomain}`          
    };
    this.authForm.id_customer = `${data.customer}`  
    this.authForm.phone = `${data.phone}`;
    this.authForm.int_phone_codes = `${data.id_input}`;
    this.authForm.name = `${data.name} ${data.lastname}`;   
    this.otpService.requestCodeSMS(dtaSend).subscribe(
      res => {
        ////console.log('response',res);      
      },
      error => {
        ////console.log(error);
        this.ErrorMsg='Algo salio mal, porfavor vuelva a intentar' 
        this.openAlertModal();
      }
    );
  }

  signInOTP(data): void {
   
    this.authForm.code = data;
    this.authForm.provider = 'otp';
    this.auth();
  }

  privacyPolicies(data): void {
    ////console.log('entro en privacyPolicies')
    this.createSession();
    if (data === 'accept') {
      // ////console.log('entro en privacyPolicies')
      const id = this.usrAuth.id;
      this.updateStatusUsr();
      this.getByIdUsrRespForm(id);
    } else {     
      ////console.log('data',data)
      // this.signOut();
    }
  }

  closeModalOtp(): void {
    this.modalOtp = document.getElementById('modalOtp');
    this.modalOtp.style.display = 'none';
  }

  signOut(): void {
    this.authService.signOut();
  }

  getUrl(){
    //obtenemos dominio de la pagina actual     
    let urlMeeting = this.url;
    let posUrl = urlMeeting.lastIndexOf("/");
    let posNum = posUrl;
    let newUrl= this.url.slice(0,posNum);
    ////console.log('NewUrl', newUrl);
    localStorage.setItem('urlOrigin',newUrl+'/meeting-confirmation/');    
  }

}
