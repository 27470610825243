import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscriber } from 'rxjs';
import { FomrsService } from '../../services/fomrs.service';
import { Form } from '../../models/forms.model';

@Component({
  selector: 'app-edit-forms',
  templateUrl: './edit-forms.component.html',
  styleUrls: ['./edit-forms.component.css']
})
export class EditFormsComponent implements OnInit {

  public formData: Form[];

  constructor(private formsServices: FomrsService) { 
    localStorage.setItem('EditMode','true');
  }

  ngOnInit(): void {

    this.formsServices.getForms().subscribe(
      response => {
        if (response.correct) {
          this.formData = response.resp;
        }
      },
      error => {
        //console.log(error);
      }
    );

  }

  getParams(): void {
  }

}
