<div *ngIf="mobilUI" style="background: #053890; width: 110%; height: 110%; position: absolute; z-index: -11111;"></div>    
  <nav  class="sidebar_collapse" id="sidebar" style="background-color: #00669A;">
    <div class="sidebar-header" style="background-color: #00669A;">    
        <img src="../../../assets/img/logo.png" alt="logo" style="width: 100px; margin-left: 50px; " >
    </div>

    <ul class="list-unstyled components">

        <li [routerLinkActive]="['active']" *ngIf="dataSesion.role === 'admin'">
            <a [routerLink]="['list-forms']">Formularios</a>
        </li>

        <li [routerLinkActive]="['active']" *ngIf="dataSesion.role === 'admin'">
            <a [routerLink]="['list-setting-form']">Configuración Form.</a>
        </li>

        <li [routerLinkActive]="['active']" *ngIf="dataSesion.role === 'admin'">
            <a [routerLink]="['list-customer-form']">Formularios clientes</a>
        </li>

        <li  *ngIf="dataSesion.role === 'specialist' && dataSesion.logical_medical != 'urology'  && dataSesion.logical_medical != 'preconsult'">
          <a (click)="openModal()">Crear un nuevo formulario</a>       
        </li>

             <li  *ngIf="dataSesion.role === 'specialist' && dataSesion.logical_medical == 'urology' ||dataSesion.role === 'doctor' && dataSesion.logical_medical == 'urology'||dataSesion.role === 'doctor' && dataSesion.logical_medical != 'urology'" >
          <a routerLinkActive="menu-item-selected" [routerLink]="['/medical-dashboard']">Tablero General</a>       
        </li>
        <li *ngIf="(dataSesion.role === 'specialist' || dataSesion.role === 'doctor') && (dataSesion.logical_medical == 'urology'|| dataSesion.logical_medical == 'preconsult')" >
          <a routerLinkActive="menu-item-selected" [routerLink]="['/patient','new']">Crear un nuevo paciente</a>       
        </li>      
        <li  *ngIf="dataSesion.role === 'specialist' && dataSesion.logical_medical == 'urology' ||dataSesion.role === 'doctor' && dataSesion.logical_medical == 'urology'">
          <a [routerLink]="['/patient-consults']">Consulta pacientes</a>       
        </li>


        <li  *ngIf="dataSesion.role === 'doctor' && dataSesion.logical_medical == 'urology'">
          <a  [routerLink]="['/finalizar-page']">Finalizados</a>       
        </li>
        <li  *ngIf="dataSesion.role === 'specialist' && dataSesion.logical_medical == 'urology'">
          <a [routerLink]="['/finalizar-page']">Finalizados</a>       
        </li>

        <li [routerLinkActive]="['active']"  *ngIf="dataSesion.role === 'customer' || dataSesion.role === 'respondent' ">
          <a [routerLink]="['list-respondent-form']">Formularios pacientes</a>
        </li>

        <li   *ngIf="dataSesion.role === 'customer' || dataSesion.role === 'respondent' ">
          <a [routerLink]="['/my-qr']">
            Mi código QR 
            <span class="material-icons">
              qr_code
              </span>
          </a>       
        </li>    

        <!-- <li *ngIf="dataSesion.role != 'doctor' && dataSesion.logical_medical != 'urology'" [routerLink]="['/team']" routerLinkActive="router-link-active" >
          <a  style="display: block;">Conoce a nuestro equipo</a>
        </li> -->
    </ul>
 
      <label style="font-size: 12px;
      margin-top: 1%;
      margin-left: 5%;     
      right: 0;
      text-align: center; z-index: 99; color: rgb(255, 255, 255);"> v1.1.12</label>  

</nav>
