import { Component, OnInit, Output, EventEmitter, Input,Inject  } from '@angular/core';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ParametersService } from '../../../services/parameters.service';
import { MatDialog } from '@angular/material/dialog';




@Component({
  selector: 'app-mdl-otp',
  templateUrl: './modal.otp.component.html',
  styleUrls: ['./modal.otp.component.css']
})
export class ModalComponent implements OnInit {

  @Input() parameters: any;
  @Output() RequestCodeSMS = new EventEmitter();
  @Output() SignInOTP = new EventEmitter();
  @Output() CloseModalOtp = new EventEmitter();

  public otpForm: any;
  public modalOtp: any;
  public intPhoneCodes: any;
  public url : any;
  public parameter: any;
  

  constructor(private modalService: NgbModal,
              public dialogRef: MatDialogRef <ModalComponent>,@Inject(MAT_DIALOG_DATA)public message:string,
              private parametersService: ParametersService,
             ) {
    this.otpForm = {
      name: null,
      lastname: null,
      id_input: 0,
      phone: null,
      codeotp: null,
      customer: null
    };
    this.getIntPhoneCodes();
  }

  ngOnInit(): void {
    this.url=window.location.href;
   }

  openModalOtp(): void {
    this.modalOtp = document.getElementById('modalOtp');
    this.modalOtp.style.display = 'block';
  }
  closeModal(){
    this.dialogRef.close();
   }
  requestCodeSMS(): void {
    if (this.otpForm.name != null && this.otpForm.lastname != null && this.otpForm.id_input != null && this.otpForm.phone != null) {
     
      if(this.url==='http://test.pasaportecovid.online:4200/login'|| this.url==='http://localhost:4201/login'){
        this.otpForm.customer = '2';    
      }
      if(this.url==='http://forms.e-processmed.com:4200/login'|| this.url==='http://localhost:4200/login'|| this.url==='http://covid19.e-processmed.com/'){      
        this.otpForm.customer== '1';      
      }      
      this.dialogRef.close(this.otpForm);
    } else {
      document.getElementById('msgform').innerHTML = 'Nombre, Apellido, Código de país y Teléfono son datos requeridos';
    }
  }
  getIntPhoneCodes(): any {
    this.parametersService.getParameters().subscribe(
      resp => {
        //console.log('respuesta int', resp.resp[1].params[0].data)
        
        const answer = resp.resp;
        const intPhoneCodes = answer.find((params) => {
          return params.name === 'int_phone_codes';
        });
        //console.log('intPhoneCodes', intPhoneCodes.params[0].data)
        this.intPhoneCodes = {
          name: 'Código Int.',
          id_input: 'id_input',
          params: [intPhoneCodes.params[0].data]
        };
        this.otpForm.codeotp=[intPhoneCodes.params[0].data] 
        this.parameter=[intPhoneCodes.params[0].data]
      },
      err => {
        //console.log(err+ 'error');
      }
    );
  }
}
