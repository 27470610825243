


  <div class="modal-header">
    <h3 class="modal-title modal_title" id="mdl-otp">Acceder con SMS</h3>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="guide_text">Por favor, ingrese su nombre y apellido, su país y su número de teléfono y solicite su código.</p>
    <form >
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <input class="" type="text" name="name" id='name' #name="ngModel" [(ngModel)]="otpForm.name" placeholder="Nombre" required>
          </div>
          <div class="col-sm-6">
            <input class="" type="text" name="lastname" id='lastname' #lastname="ngModel" [(ngModel)]="otpForm.lastname" placeholder="Apellido" required>
          </div>
          <div class="col-sm-4">            
            <select name="Seleccionar" [(ngModel)]="otpForm.id_input">            
              <option *ngFor="let param of parameter[0]" [value]="param.code">{{param.name}}</option>
            </select>            
          </div>
          <div class="col-sm-8">
            <input class="" type="number" name="phone" id='phone' #phone="ngModel" [(ngModel)]="otpForm.phone" placeholder="Teléfono" required>
          </div>         
        </div>      
      </div>
      <p class="msgform" id="msgform" #msgform></p>
    </form>
  </div>  
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <button mat-raised-button style="background-color:  #00669A; color: #ffffff;" class="btn-block btn-lg m-t-20 m-b-10" type="button" (click)=" requestCodeSMS()">Solicitar Código</button>  
  </div>



