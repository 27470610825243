import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-combobox',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.css']
})
export class ComboboxComponent implements OnInit {

  @Input() public dtaQuestions: any;

  constructor() { }

  ngOnInit(): void {}

}
