import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { globalData } from '../global/global_data';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public session: boolean;
  public usrSession: any;
  

  constructor(
    private http: HttpClient,
    private userService: UsersService,
    private messaging: AngularFireMessaging
  ) {
    this.usrSession = {
      id: null,
      name: null,
      role: null,
      token: null
    };
  }

  auth(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.authUsr}`, body, {headers: headers});
  }

  getUserExists(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.qyrUsr}`, body, {headers: headers});
  }

  ncryptUserData(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.sendNcrypt}`, body, {headers: headers});
  }
  dcryptUserData(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.sendDcrypt}`, body, {headers: headers});
  }

  loggedIn(): boolean {
    this.session = false;
    if (localStorage.getItem('token')) {
      this.session = true;
    }
    return this.session;
  }

  getDataSesion(): any {
    this.usrSession = {
      id: localStorage.getItem('id'),
      name: localStorage.getItem('name'),
      role: localStorage.getItem('role'),
      mail: localStorage.getItem('email'),
      token: localStorage.getItem('token'),
      intPhoneCodes: localStorage.getItem('intPhoneCodes'),
      customerId: localStorage.getItem('customer_id'),
      logical_medical:localStorage.getItem('logical_medical'),
      id_stablishment:localStorage.getItem('id_stablishment'),
      hash:localStorage.getItem('hash_stablishment')
    };
    return this.usrSession;
  }

  createForm(data): Observable<any> {
    //console.log('para enviar a createForm',data)
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.crtformsUsr}`, body, {headers: headers});
  }
  

  signOut(): void {
    localStorage.clear();
    // this.router.navigate(['/login']);
    location.reload();
  }

  registerFCMToken(user: any){
    this.messaging.requestToken.subscribe(
      (token: String) => {
        if(token && token.trim()){
          console.log('User',user);
          console.log('token', token);
          let tokens: String[] = user.tokens_push;
          if(tokens.indexOf(token) == -1) {
            tokens.push(token);
            console.log(tokens);
            this.userService.updateTokensUser(user.id,tokens)
                .subscribe(
                  response => console.log('Token registrado', response),
                  error => console.log(error)
                );
          }else{
            console.log('No es necesario añadir Token');
          }
        }
      }, error => console.log('Ocurrió un error al obtener el token', error)
    );
  }

}
