import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { globalData } from '../global/global_data';

@Injectable({
  providedIn: 'root'
})
export class RespondentFormsService {

  constructor( private http: HttpClient ) { }

  getRespForms(customer_id): Observable<any> {    
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.dtaFrm}/${customer_id}`);
  }

  getByIdRespForm(id): Observable<any> {
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.getDtaFrm}/${id}`);
  }

  getByIdUsrRespForm(id): Observable<any> {
    const body: any = JSON.stringify({id});
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.getDtaFrmUsr}`, body, {headers: headers});
  }

  //para traer los formularios creados por id de usuario
  getAllDataByIdUsrRespForm(id): Observable<any> {   
    
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.getAllDtaFrmUsr}/${id}`);
  }

  createRespForm(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.crtDtaFrm}`, body, {headers: headers});
  }

  SendDataBlockChain(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.sendDataBlock}`, body, {headers: headers});
  }

  CallDataBlockChain(data): Observable<any> {
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.callDataBlock}`, body, {headers: headers});
  }

  updateRespForm(data,type): Observable<any> {
    data['type'] = type;
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(`${globalData.urls.rootURI}${globalData.urls.updDtaFrm}`, body, {headers: headers});
  }

  uploadFile(data): Observable<any> {
    console.log('Guardando pdf y enviando en la siguiente ruta:',globalData.urls.fileURl+globalData.urls.upFileDtaFrm);
    //console.log(data);
    // data['type'] = 'generic';
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(globalData.urls.fileURl+'/'+globalData.urls.upFileDtaFrm, data);
  }
  // uploadFile(data): Observable<any> {
  //   console.log('Guardando pdf',data,'y enviando en la siguiente ruta:',globalData.urls.fileURl);
  //   // data['type'] = 'generic';
  //   const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
  //   return this.http.put(globalData.urls.fileURl, data);
  // }

  

}
